<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <PageNotFoundMobile  v-if="matches" />
    <PageNotFoundDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import PageNotFoundMobile from "./PageNotFound_Mobile.vue";
  import PageNotFoundDesktop from "./PageNotFound_Desktop.vue";
 
  export default {
    name: 'PageNotFound',
    components: {
      MatchMedia,
      PageNotFoundMobile,
      PageNotFoundDesktop,
    },
  }
</script> 