import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        inSession: false,
        current_page: 'Welcome',
        previous_page: 'Login',
        user:{},
        user_id:0,
        profile_picture:''
    },
    getters:{
        inSession: state => {
            return state.inSession
        },
        currentUser: state => {
            return state.user
        },
        currentUserID: state => {
            return state.user_id
        },
        profilePicture: state => {
            return state.profile_picture
        },
        currentPage: state => {
            return state.current_page
        },
        previousPage: state => {
            return state.previous_page
        }
    },
    mutations:{
        login:state=>{
            state.inSession = true;
        },
        logout:state=>{
            state.inSession = false;
        },
        setUser:(state,currentUser)=>{
            state.user = currentUser;
        },
        setUserID:(state,userID)=>{
            state.user_id = userID;
        },
        setProfilePicture:(state,profile_picture)=>{
            state.profile_picture = profile_picture;
        },
        setPage:(state,page)=>{
            state.previous_page = state.current_page;
            state.current_page = page;
        },
    }
})