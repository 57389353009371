<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title"><b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</h1>
            <p class="main-subtitle">make yourself known to all wine lovers</p>
          </div>  
        </b-col>
      </b-row> 
      <br>
      <br>
      <br>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col v-if="!isConfirmed" cols="10" sm="8" class="text-right">
          <p class="w-subtitle">Hey {{user.firstname}} {{user.lastname}}, </p>
          <p class="w-text">We received a request to change your email from {{user.email}} to <strong>{{user.new_email}}</strong> to access the WyneMaker or WyneCellar application,</p>
          <p class="w-text">If this is correct, please confirm by pushing the button below</P>
          <br>
          <b-button v-on:click="onConfirm()" class="w-button-green">I confirm</b-button>
        </b-col>
        <b-col v-else cols="10" sm="8" class="text-right">
          <p class="w-subtitle">Hey {{user.firstname}} {{user.lastname}}, </p>
          <p class="w-text">Change is succesful. Your password remains unchanged.</p>
          <p class="w-text">You can now login with <strong>{{user.email}}</strong> by pressing start.</p>
          <br>
          <b-button v-on:click="onStart()" class="w-button-green">start</b-button>
        </b-col>
      </b-row>  
    </div>
  </div>
</template>

<script>
  import Users from '../Users.js';

  export default {
    name: 'ChangeEmail',
    data() {
      return {
        debug: false,

        isLoading: true,

        user: {
        },
        user_id:0,

        isConfirmed: false,
      }
    },

    async mounted() {
      if (this.debug) console.log('ChangeEmail in mounted');

      if ( typeof this.$route.query.user_id !== "undefined") {
        await Users.getone(this.$route.query.user_id)
        .then(response=>{
          this.user = response;
          this.user_id = response._id;
          delete this.user._id
          if (this.debug) console.log("ChangeEmail in mounted: User ", this.user, " succesfully fetched from DB")
        })
        .catch(error=>{
          if (this.debug) console.log("ChangeEmail in mounted: catch await Users.getone: ", error);
        })

        if (typeof this.user === "undefined" || this.user==="") {
          let this_error = "User undefined"
          if (this.debug) console.log("ChangeEmail in mounted: ", this_error)
          this.$router.push({path:'/Error',query: {error:this_error}} );  
        }
      } else {
        if (this.debug) console.log( "ChangeEmail in mounted: ERROR : No user found.");
      }

      this.isLoading = false;
    },

    methods: {
      async onConfirm() {
        if (this.debug) console.log("Confirm in onConfirm: START");
        this.user.email = this.user.new_email;
        delete this.user.new_email;
        await Users.update(this.user_id, this.user)
        .then(response => {
          if (this.debug) console.log("Confirm in onConfirm: update user succesful with response: ", response);
          this.isConfirmed = true;
        })
        .catch(error => {
          if (this.debug) console.log("Confirm in onConfirm: catch Users.update with error: ", error);
        })
        if (this.debug) console.log("Confirm in onConfirm: STOP");
      },
      onStart() {
        console.log("Pushed start")
        this.$router.push({path:'/'});
      }
    },
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style>
