<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <WineryWinesMobile  v-if="matches" />
    <WineryWinesDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineryWinesMobile from "./WineryWines_Mobile.vue";
  import WineryWinesDesktop from "./WineryWines_Desktop.vue";
 
  export default {
    name: 'WineryWines',
    components: {
      MatchMedia,
      WineryWinesMobile,
      WineryWinesDesktop,
    },
  }
</script> 