import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import VueCryptojs from 'vue-cryptojs'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-search-select/dist/VueSearchSelect.css'

import VueAnalytics from 'vue-analytics';

import { store } from './store/store.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue);
Vue.use(VueCryptojs);

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-220960889-1',
  router, // The best way to track which pages users access is throw your vue-router in your Vue app. 
});

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')