<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container>
        <b-row>
          <b-col align="center">
            <div class="m-box">          
              <b-row class="justify-content-center" v-if="wine.image_files.length > 0">
                <b-carousel
                id="carousel-fade"
                :interval="4000"
                controls
                indicators
                background="#ffffff"
                img-width="100"
                img-height="300"
                style="width:max-content;height:auto"
                >
                  <b-carousel-slide v-for="image_file in wine.image_files" v-bind:key="image_file.dataURL">
                    <template v-slot:img>
                      <b-img   
                        v-bind:src="image_file.dataURL"
                        style="height:50vw;position:relative;right:0px"
                      />
                    </template>
                  </b-carousel-slide>
                </b-carousel>
              </b-row>
              <b-row v-else>
                <b-img center
                  style="height:50vw;position:relative;right:0px"
                  :src="require('@/assets/img/bottle.png')"
                ></b-img>
              </b-row> 
              <b-row>
                <b-col>
                  <div>
                    <div>
                      <h1 class="preview-title">{{this.wine.name}}</h1> 
                      <h5 class="preview-subtitle">{{this.wine.type}}</h5>
                      <hr>
                      <p class="preview-text">{{this.wine.region}}, {{this.wine.country}}</p>
                      <p class="preview-text">{{this.wine.appellation}}, {{this.wine.classification}}</p>

                    </div>
                    <div v-for="(value, key) in this.wine" v-bind:key="key">
                      <div v-if="key==='tasting_notes' && value.length>0">
                        <hr>
                        <p  class="w-text" style="font-style:italic">{{value}}</p>
                      </div>  
                      <div v-if="key==='grapes' || key==='vines' || key==='vinification' || key === 'yields'">
                        <p  class="w-text" v-if="value.length>0"><b>{{ key }}</b> : {{value}}</p>
                      </div>
                    </div>
                    <hr>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="m-box">
              <b-row >
                <b-button v-on:click="onBack()" class="w-button-pink-full">back</b-button>
              </b-row>
            </div>
          </b-col>
        </b-row> 
      </b-container>
    </div>
  </div>
</template>
<script>
  import Wines from '../Wines.js'
  import Wineries from '../Wineries.js'
  
  export default {
    name: 'Wine',
    components: {
    },
    data() {
      return {
        isLoading : true,
        wine: {},
        winery: {},
      }
    },
  async mounted(){
    if (this.debug) console.log('WineShow in mounted: START');
    
    await Wines.getone(this.$route.query.wine_id)
    .then(response => {
      this.wine = response
    })
    .catch(error => {
      console.log('WineShow in mounted: Catch await Wines.getone - ' + error);
    })
    
    await Wineries.getone(this.wine.winery_id)
    .then(response => {
      this.winery = response
    })
    .catch(error => {
      console.log('WineShow in mounted: Catch await Winary.getone - ' + error);
    })

    this.isLoading = false;
  },
  methods: {
    onBack() {
      this.$router.push({path:'/'+ this.winery.webname})
    }
  }
}
</script>