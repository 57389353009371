// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/confirm_change_email/';
// const url = WEBSERVER + ':'+ PORT + '/api/confirm_change_email/';

const url = API + '/confirm_change_email/';
const debug = false;

class ChangeEmail {
    static go(postdata) {
        if (debug) console.log('ChangeEmail.sendEmail: id=',postdata.id)
        if (debug) console.log('ChangeEmail.sendEmail: new_email=',postdata.email)
        return axios.post(url,postdata)
            .then(response => {
                if (debug) console.log('ChangeEmail.sendEmail: ',response)
            })
            .catch(error => {
                console.log('ChangeEmail.sendEmail: ',error)
            })
    }
}

export default ChangeEmail