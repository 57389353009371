<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <div style="height:5vh">
            </div>
            <div style="margin-left:5vw; margin-right:5vw">
              <p style="text-align:right" class="w-subtitle">add wines</p> 
              <b-row class="justify-content-center">
                <b-col 
                    class="w-tile m-2 d-inline-flex justify-content-center"
                    xs="12" sm="6" md="4" lg="2" xl="2"
                    v-on:click="$router.push({path:'/Winery/WineEdit', query: {winery_id:$route.query.winery_id}})"
                    style ="display:block; margin:20px !important"
                >
                  <b-img
                    width=300px
                    height=200px
                    :src="require('@/assets/img/plus.png')"
                  >
                  </b-img> 
                </b-col>
                
                <b-col
                  v-for="(wine, index) in wines"
                  v-bind:item="wine"
                  v-bind:index="index"
                  v-bind:key="wine._id" 
                  class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                  xs="12" sm="6" md="4" lg="2" xl="2"
                  style ="display:block; min-width:300px; margin:20px !important"
                >
                  <b-row 
                    style="width:95%"
                    v-on:click="$router.push({path:'/Winery/WineEdit', query: {winery_id:$route.query.winery_id,wine_id:wine._id }})"
                  >
                    <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                      <b-img center v-if="wine.image_files[0]"
                        style="height:100%;width:auto;position:absolute;right:0px"
                        v-bind:src="wine.image_files[0].dataURL" 
                      ></b-img>
                      <b-img center v-else
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle.png')"
                      ></b-img>
                    </b-col>
                    <b-col cols="9" class="d-table-cell">
                      <p class="t-title">{{wine.name}}</p>
                      <p class="t-subtitle" v-if="wine.type">{{wine.type}}</p>
                      <hr style="margin:5px 0 5px 0">
                      <p class="t-text" v-if="wine.appellation">{{wine.appellation}}</p>
                      <p class="t-text" v-if="wine.classification">{{wine.classification}}</p>
                      <p class="t-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                    </b-col>
                  </b-row>
                  <div v-on:click.stop="onDeleteWine(index)" class="delete-symbol">
                    <b-img
                      :src="require('@/assets/img/delete.png')"
                      style="width:100%;height:100%;border-radius:50%;border-style:none;position:relative;top: -35px;"
                    ></b-img>
                  </div>
                </b-col>
              </b-row>
              <br><br>
              <b-row>
                <b-col  class="text-left">
                  <b-button v-on:click="onPrevious()" class="w-button-pink">previous</b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button v-on:click="onNext()" class="w-button-green">next</b-button>
                </b-col>
              </b-row>
              <br>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    
    <b-modal id="deleteWine" ref="deleteWine" title="delete wine" @ok="deleteWine">
      <b-row v-if="current_index >=0">
        <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
          <b-img center v-if="wines[current_index].image_files[0]"
            style="height:200px;width:auto;right:0px"
            v-bind:src="wines[current_index].image_files[0].dataURL" 
          ></b-img>
          <b-img center v-else
            :src="require('@/assets/img/delete.png')"
            style="height:200px;width:auto;right:0px;"
          ></b-img>
        </b-col>
        <b-col cols="9" class="d-table-cell">
          <p class="t-title">{{wines[current_index].name}}</p>
          <p class="t-subtitle" v-if="wines[current_index].type">{{wines[current_index].type}}</p>
          <hr>
          <p class="t-text" v-if="wines[current_index].appellation">{{wines[current_index].appellation}}</p>
          <p class="t-text" v-if="wines[current_index].classification">{{wines[current_index].classification}}</p>
          <p class="t-smalltext" v-if="wines[current_index].grapes">{{wines[current_index].grapes}}</p>
        </b-col>
      </b-row>
      <p class="t-title text-center"> ARE YOU SHURE YOU WANT TO DELETE THIS WINE?</p>
      
      <template #modal-footer="{ ok, cancel }">
        <b-row style="width:100%">  
          <b-col class="text-left">
            <b-button class="w-button-pink" @click="cancel()">
              cancel
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              ok
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'
  
  import Wineries from '../Wineries.js'
  import Wines from '../Wines.js'

  export default {
    name: 'WineryWines',
    components: {
      NoAccess,
      Menu
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        winery: {
          wine_ids: [],
          image_files: []
        },
        winery_id:0,

        wines:[],

        current_index:-1,

        isValid: true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WineryWines in mounted: Fetching winery from DB '+ this.$route.query.winery_id);
      
      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.winery = response;
        this.winery_id = this.winery._id;
        delete this.winery._id;
        if (this.debug) console.log("WineryWines in mounted: Winery "+ this.winery.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log("WineryWines in mounted: catch await Wineries.getone ERROR : ", error);
      })

      if (typeof this.winery.wine_ids === "undefined" || this.winery.wine_ids === null || this.winery.wine_ids === "") {
        this.winery.wine_ids = [];
      }
      for (var i=0; i < this.winery.wine_ids.length; i ++) {
        if (typeof this.winery.wine_ids[i] != "undefined" && this.winery.wine_ids[i] != null && this.winery.wine_ids[i] != "") {
           await Wines.getone(this.winery.wine_ids[i])
          .then(response=> {
            this.wines.push(response)
            if (this.debug) console.log('WineryWines in mounted: Wine with id= ', this.wines[i], ' succesfully fetched from DB')
          })
          .catch(error=>{
            if (this.debug) console.log('WineryWines in mounted: catch await Wines.getone ERROR : ' + error);
          })
        } else {
          if (this.debug) console.log('WineryWines in mounted: ERROR wine_ids[',i,'] = ', this.winery.wine_ids[i]);
        }
      }

      this.isLoading = false;
    },
    methods: { 
      async onSave() {
        if(this.isValid){
          await Wineries.update(this.$route.query.winery_id,this.winery)
          if (this.debug) console.log("WineryWines in onSave(): Submitted : ", this.winery)
        } 
      },
      async onNext() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WineryWines in onNext():", response)
          this.$store.commit('setPage','Preview')
          this.$router.push({path:'/Winery/Preview', query: {winery_id:this.$route.query.winery_id }} );
        })
        
      },
      async onPrevious() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WineryWines in onPrevious():", response)
          this.$store.commit('setPage','Pictures')
          this.$router.push({path:'/Winery/Pictures', query: {winery_id:this.$route.query.winery_id }} );
        }) 
      },
      onDeleteWine(i){
        this.current_index = i
        this.$bvModal.show('deleteWine')
      },

      async deleteWine(){
        let this_wine_id = this.winery.wine_ids[this.current_index]
        
        await Wines.delete(this_wine_id)
        .then(response => {
          console.log("WineryWines in deleteWine(): wine with id ", this_wine_id, " deleted with response : ", response)
        })
        .catch(error => {
          console.log('WineryWines in deleteWine() : catch await Wines.delete ERROR : ' + error);
        })

        this.winery.wine_ids.splice(this.current_index,1)
        await Wineries.update(this.winery_id,this.winery)
        .then(response => {
          console.log("WineryWines in deleteWine(): winery with id ", this.winery_id, " updated with response : ", response)
        })
        .catch(error => {
          console.log('WineryWines in deleteWine() : catch await Winery.update ERROR : ' + error);
        })

        this.wines.splice(this.current_index,1);
        this.current_index = -1;
      },
    }
  }
</script>
