// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/classifications/'
// const url2 = WEBSERVER + ':3000/api/classifications_from_geography/'

// const url = WEBSERVER + ':' + PORT + '/api/classifications/'
// const url2 = WEBSERVER + ':' + PORT + '/api/classifications_from_geography/'

const url = API + '/classifications/'
const url2 = API + '/classifications_from_geography/'
const debug = false

class Classifications {
    // get all classifications
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(classification=> ({
                        ...classification,
                        name: classification.name
                    }))
                );
                if (debug) console.log("Classifications.get: data = ", data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one classification
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Clasiifications.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Clasiifications.getone: ',error)
            })
    }

    // get one classification from Geography ID
    static get_fromGeographyID(id) {
        return axios.get(`${url2}${id}`)
            .then(response => {
                if (debug) console.log('Classifications.get_fromGeographyID: ',response.data)
                return response.data
            })
            .catch(error => {
                if (debug) console.log('Classifications.get_fromGeographyID: ',error)
            })
    }

    // create classifications
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Classifications.create: ',response)
                return response.data._id
            })
            .catch(error => {
                if (debug) console.log('Classifications.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Classifications.update: id=',id)
        if (debug) console.log('Classifications.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Classifications.update: ',response)
            })
            .catch(error => {
                if (debug) console.log('Classifications.update: ',error)
            })
    }

    // delete country
    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default Classifications