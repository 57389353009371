// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':' + PORT+ '/api/reset_password_maker/';

const url = API + '/reset_password_maker/';
const debug = true;

class ResetPassword {
    static sendEmail(id) {
        if (debug) console.log('ResetPassword.sendEmail: id=',id)
        return axios.post(`${url}${id}`)
            .then(response => {
                if (debug) console.log('ResetPassword.sendEmail: ',response)
            })
            .catch(error => {
                console.log('ResetPassword.sendEmail: ',error)
            })
    }
}

export default ResetPassword