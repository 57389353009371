<template>
  <div>
  <b-container fluid class="space-2 space-3--lg"> 
    <b-form autocomplete="off">
      <b-row>
        <b-col md="3" class="d-flex justify-content-center" style="padding:0">
          <div class="w-box text-center" style="width:180px;height:180px"> 
            <b-avatar v-if="user.image_files.length" 
              size="100px" 
              v-bind:src="user.image_files[0].dataURL"
            >
            </b-avatar>
            <b-avatar v-else 
              size="100px" 
              :src="require('@/assets/img/profile-picture.jpg')"
            >
            </b-avatar>
            <p ref="pictureComment" class="profile-name">{{pictureComment}}</p>
            <div class="dropbox-overlay text-center" style="width:180px;height:180px">
              <vue-dropzone 
                ref="imgDropzone" id="profiledropzone" 
                :options="dropzoneOptions"
                @vdropzone-thumbnail="vfileAdded" 
              >
              </vue-dropzone>    
            </div>
          </div>
          <br>
          <div class="text-center">
            <p class="bottom-center-logo"><b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</p>
          </div>
        </b-col>
        <b-col md="8" style="margin-left:10px" class="d-table">
          <div class="w-box">
            <h1 class="w-subtitle">personal information</h1>
            <b-form-group>
              <label class="w-label" for="input-name">first name</label>
              <b-form-input
              id="input-firstname"
              class = "w-input"
              v-model="user.firstname"
              @change="changeName"
              v-bind:class="{'is-invalid' : !this.firstnameValid}"
              aria-describedby="input-live-help input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{firstnameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
                      
            <b-form-group>
              <label class="w-label" for="input-lastname">last name</label>
              <b-form-input
              id="input-lastname"
              class = "w-input"
              v-model="user.lastname"
              @change="changeName"
              v-bind:class="{'is-invalid' : !this.lastnameValid}"
              aria-describedby="input-live-help input-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{lastnameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="w-box">
            <h1 class="w-subtitle">wyne information</h1>
            <b-form-group>
              <label class="w-label" for="input-name">winery name</label>
              <b-form-input
              id="input-wineryname"
              class = "w-input"
              v-model="winery.name"
              v-bind:class="{'is-invalid' : !this.winerynameValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{winerynameFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>

          <div class="w-box">
            <h1 class="w-subtitle">account information</h1>
            <b-form-group> 
              <label class="w-label" for="input-name">email</label>
              <b-form-input
              id="input-email"
              class = "w-input"
              v-model="user.email"
              v-bind:class="{'is-invalid' : !this.emailValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{emailFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

          <b-form-group>
            <label class="w-label" for="input-name">password</label>
            <b-form-input
              id="input-password"
              class = "w-input"
              type="password"
              v-model="password"
              v-bind:class="{'is-invalid' : !this.passwordValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
            </b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              {{passwordFeedback}}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <label class="w-label" for="input-name">confirm password</label>
              <b-form-input
              id="input-confirmpassword"
              class = "w-input"
              type="password"
              v-model="confirmpassword"
              v-bind:class="{'is-invalid' : !this.passwordValid}"
              aria-describedby="input-live-help input-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                {{passwordFeedback}}
              </b-form-invalid-feedback>
          </b-form-group>
          <b-form-checkbox 
            id="toggle"
            v-model="user.hasAgreedTerms"
            v-bind:class="{'is-invalid' : !this.termsValid}"
          >
            <p class="w-label">I agree to <span class="w-cursor-click" @click.stop="showTerms"><u>WyneLover Terms of Use and Privacy Policy.</u></span></p>
          </b-form-checkbox>
          <b-form-invalid-feedback id="input-live-feedback">
            {{termsFeedback}}
          </b-form-invalid-feedback>
          </div>
          <b-row>
            <b-col class="text-right" style="margin-right:30px">
              <b-button class="w-button-green" v-on:click="onRegister()">register</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  import makeWebname from '../assets/js/makeWebname.js';
  import Winery_from_Webname from '../Winery_from_Webname.js';  

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import Users from '../Users.js';
  import UserID_from_Email from '../UserID_from_Email.js';
  import Confirm from '../Confirm.js';
  import Wineries from '../Wineries.js';

  export default {
    name: 'Register',
    components:{
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        debug: false,

        //Working Objects
        user: {
            firstname: '',
            lastname: '',
            email: '',
            hashed_password: '',
            profile_picture: '',
            winery_id: '',
            image_files: [],
            tasting_ids: [],
            isConfirmed: false,
            hasAgreedTerms: false,
            showWelcome: true,
        },
        user_id:0,

        winery: {
            name: '',
            webname: '',
            user_id: 0,
            wine_ids: [],
            image_files: []
        },
        winery_id:0,

        pictureComment: 'name comes here',

        // Validation Logic
        firstnameValid : true,
        firstnameFeedback : '',

        lastnameValid : true,
        lastnameFeedback : '',

        winerynameValid : true,
        winerynameFeedback : '',

        confirmpassword : '',
        password : '',

        emailValid : true,
        emailFeedback : '',

        passwordValid : true,
        passwordFeedback : '',

        termsValid : true,
        termsFeedback : '',

        formValid : false,

        // dropzone variables
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 10,
        },
      }
    },
    async mounted() {

    },
    methods: {
        validateEmail() {
          let hasAt = false;
          let hasDot = false;
          for (let i=0;i < this.user.email.length;i++) {
              if (this.user.email[i] === '@') {
                  hasAt = true;
              }
              if (this.user.email[i] === '.') {
                  hasDot = true;
              }
          }
          this.emailValid = hasAt && hasDot
          if ( ! this.emailValid) {
              this.emailValid = false
              this.emailFeedback='This is an invalid email format';
          }
        },

        validatePassword() {
          let min8char = false   
          let hasUpper = false
          let hasLower = false
          let hasDigit = false
          if ( this.password.length > 7) {
            min8char = true
          }
          for (let i=0; i < this.password.length;i++) {
            let c = this.password[i]
            if (c >= '0' && c <= '9') {
              hasDigit = true
            } else {
              if (c === c.toUpperCase() ) {
                hasUpper = true
              }
              if (c === c.toLowerCase() ) {
                hasLower = true
              }
            }
          }
          this.passwordValid = min8char && hasUpper && hasLower && hasDigit
          if ( !this.passwordValid ) {
            this.passwordFeedback = 'Password needs at least'
            if ( !min8char) {
              this.passwordFeedback += ' 8 characters'
            }
            if( !hasUpper ) {
              this.passwordFeedback += ' one uppercase'
            }
            if( !hasLower ) {
              this.passwordFeedback += ' one lowercase'
            }
            if( !hasDigit) {
              this.passwordFeedback += ' one digit'
            }
          }
          if (this.debug) console.log("validatePassword: START")
          if (this.debug) console.log("validatePassword: min8char = ", min8char)
          if (this.debug) console.log("validatePassword: hasUpper = ", hasUpper)
          if (this.debug) console.log("validatePassword: hasLower = ", hasLower)
          if (this.debug) console.log("validatePassword: hasDigit = ", hasDigit)
          if (this.debug) console.log("validatePassword: --------------------")
          if (this.debug) console.log("validatePassword: new_passwordFeedback = ", this.new_passwordFeedback)
          if (this.debug) console.log("validatePassword: STOP")
        },
        
        validateTerms() {
          if (!this.user.hasAgreedTerms) {
            this.termsValid = false
            this.termsFeedback = 'You have to agree upon the terms to continue'
          } else {
            this.termsValid = true
            this.termsFeedback = ''
          }
        },
        
        async validate(){
          //chack validity of name fields
          if (this.winery.name.length < 3) {
            this.winerynameValid = false
            this.winerynameFeedback = 'Winery Name should be at least 3 characters long'
          } else {
             this.winerynameValid = true
          }
          //check validity of email field
          this.validateEmail()
          
          //check if email is already know
          //var userExists = await Users.doesExists(this.user.email)
          let challenge = {"email": this.user.email};
          let userExists = false;
          await UserID_from_Email.getToken(challenge)
          .then(response => {
            if (response === 0) {
              if (this.debug) console.log("Register in validate: user with email ", this.user.email, " has no id and does NOT exists in DB");
              userExists = false;
            } else {
              if (this.debug) console.log("Register in validate: user with email ", this.user.email, " has id = ", response, " and exists in DB");
              userExists = true;
            }
          })
          .catch(error => {
            userExists = true;
            if (this.debug) console.log("Register in validate: await UserID_from_Email - ", error);
          })

          if (userExists === false ) {
            if (this.debug) console.log("Register in validate: User " + this.user.email + " does not exists => creating")
            //check validaty of password
            this.validatePassword()
            
            if (this.password !== this.confirmpassword) {
              this.passwordValid = false
              this.passwordFeedback = 'The passwords did not correspond. Try again.'
            }
            
          } else {
            this.emailValid = false
            this.emailFeedback = 'User ' + this.user.email + ' exists. Please Login'
          }
          this.validateTerms()
          this.formValid = this.winerynameValid && this.emailValid && this.passwordValid && this.termsValid
        },

        async onRegister() {
          if (this.debug) console.log("Register in onRegister: Register pressed");
          this.user.email = this.user.email.toLowerCase();
          this.firstnameFeedback = ""
          this.lastnameFeedback = ""
          this.winerynameFeedback = ""
          this.emailFeedback = ""
          this.passwordFeedback = ""
          this.termsFeedback = ""

          await this.validate()
          if (this.debug) console.log("Register in onRegister: form.valid=",this.formValid);
          
          if (this.formValid) {
            // HERE COMES THE DB STUFF
            if (this.debug) console.log('Register in onRegister: Extracted this.user from the form :', this.user)
            
            this.user.hashed_password = this.CryptoJS.SHA256(this.password).toString();
            if ( this.user.image_files.length > 0) {
              this.user.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
            }
            await Users.create(this.user)
            .then(response=>{
              if (this.debug) console.log("Register in onRegister: user successfully created - ", response);
              this.user_id = response
            })
            .catch(error=>{
               if (this.debug) console.log("Register in onRegister: catch in Users.create - ERROR - ", error);
            })

            this.winery.user_id = this.user_id;
            this.winery.webname = await this.changeWebname(this.winery.name);
            await Wineries.create(this.winery)
            .then(response =>{
              if (this.debug) console.log("Register in onRegister: Winery successfully created - ", response);
              this.winery_id = response
            })
            .catch(error => {
              if (this.debug) console.log("Register in onRegister: catch in Wineries.create - ", error);
            })

            this.user.winery_id = this.winery_id;
            await Users.update(this.user_id,this.user)
            .then(response =>{
              if (this.debug) console.log("Register in onRegister: user successfully updated - ", response);
            })
            .catch(error => {
              if (this.debug) console.log("Register in onRegister: catch in Users.update - ", error);
            })

            this.$store.commit('setUser',this.user)
            
            await Confirm.sendEmail(this.user_id)
            .then(response=>{
              if (this.debug) console.log("Register in onRegister: ",this.user.email," has been succesfully registered ", response)
              this.$router.push({path:'/RegistrationReceived', query: {user_id:this.user_id}} );  
            })
            .catch(error => {
              if (this.debug) console.log('Register in onRegister: ', error)
            })
          }
        },

        changeName() {
          this.pictureComment = '';
          if (this.user.firstname.length > 0) {
            this.pictureComment += this.user.firstname;
          }
          if (this.user.lastname.length > 0) {
            if (this.user.firstname.length >0) {
              this.pictureComment += ' ';
            }
            this.pictureComment += this.user.lastname;
          }
        },

        showTerms() {
          this.$router.push({path:'/Terms'});
        },

        async changeWebname(winery_name) {
          let doSearch = true;
          let i = 0;
          let root_webname = makeWebname(winery_name)
          let try_webname = root_webname

          while (doSearch) {
            if (this.debug) console.log("WineEdit in changeWebname: trying ", try_webname);
            let challenge = {"webname": try_webname};
            await Winery_from_Webname.getToken(challenge)
            .then(response => {
              if (response === 0) {
                if (this.debug) console.log("WineEdit in changeWebname: winery with webname ", try_webname, " has no id and does NOT exists in DB");
                doSearch = false;
              } else {
              if (this.debug) console.log("WineEdit in changeWebname: winery with webname ", try_webname, " has id = ", response, " and exists in DB");
              if (response != this.winery_id) {
                doSearch = true;
                i++;
                try_webname = root_webname + '_' + i
              } else {
                if (this.debug) console.log("WineEdit in changeWebname: winery remains the same.")
                doSearch = false;
              }
              }
            })
            .catch(error => {
              doSearch = true;
              if (this.debug) console.log("WineEdit in changeWebname: await Winery_from_Webname.getToken - ", error);
            })
          }

          return try_webname
          
          if (this.debug) console.log("WineEdit in changeWebname: webname ", try_webname, " will be used.");
        },

        async vfileAdded(new_image_file) {
          this.pictureComment = 'loading';
          this.$refs.pictureComment.$forceUpdate;
          if (this.user.image_files.length > 0) {
            var old_image_file = this.user.image_files.pop();
            this.$refs.imgDropzone.removeFile(old_image_file);
          }
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,2,3);
          new_image_file.dataURL = cc_base64;
          this.user.image_files.push(new_image_file);

          this.user.profile_picture = await cc64(org_base64,1,1);
          this.$refs.imgDropzone.removeFile(new_image_file);
          this.changeName();
        },

        template: function () {
         return `<div class="dz-preview">
                </div>`;
        },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>

.bottom-center-logo {
  left: 12.5%;
}

/* Custom Dropzone */
#profiledropzone {
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;

  background-color: transparent;
  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}

#profiledropzone .dz-message {
  position: absolute;
  color:#696969;
  bottom:-50px;
  font-size: 12px;
}

#profiledropzone .dz-preview {
  width: 110px;
  display: inline-block
}

#profiledropzone .dz-preview .dz-image {
  position: relative;
  margin-top: 60px;
  width: 80px;
  height: 80px;
  margin-left: 20px;
  margin-bottom: 20px;  
  top:-55px;
}
#profiledropzone .dz-preview .dz-image > img {
  width: 100%;
}

#profiledropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: 70% !important;
  background-size: contain;
}

#profiledropzone .dz-preview .dz-details {
  color: white;
  transition: opacity .2s linear;
  text-align: center;
}
#profiledropzone .dz-success-mark, .dz-error-mark, .dz-remove {
  display: none;
}

#profiledropzone .dz-thumbnail{
  height:100% !important;
  width:auto !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center center;
}
.thumbnail-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  color:white;
  background-color: brown;
}

#profiledropzone .dz-preview .dz-image:hover .thumbnail-overlay {
  opacity: 0.7;
}

#profiledropzone .remove {
  text-align: center;
  color:brown;
  font-size: 0.5 rem !important;
}

#profiledropzone .dz-filename {
  font-size: 0.5 rem !important;
}
</style>
