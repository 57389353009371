<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession" style="padding:0">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <b-carousel
              id="carousel-fade"
              slide = true
              :interval="4000"
              controls
              background="#fffff0"
              img-width="300"
              img-height="200"
              fade
              style="text-shadow: 1px 1px 2px #333;"
            > 
              <b-carousel-slide v-for="image_file in winery.image_files" v-bind:key="image_file.dataURL">
                <template v-slot:img>
                  <img
                    class="d-block img-fluid w-100"  
                    width="300"
                    height="200"
                    v-bind:src="image_file.dataURL"
                  >
                </template>
              </b-carousel-slide>
            </b-carousel>
            <div class="over">
              <b-row>
                <b-col>
                  <h1 class="preview-title">{{winery.name}}</h1>
                  <p class="preview-text">{{winery.description}}</p>
                </b-col>
              </b-row>         
              <hr>
              <p class="preview-subtitle" style="text-align:right!important">our wines</p>
              <b-row class="justify-content-center" style="margin:0"> 
                <b-col
                  v-for="(wine, index) in wines"
                  v-bind:item="wine"
                  v-bind:index="index"
                  v-bind:key="wine._id" 
                  class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                  xs="12" sm="6" md="3" lg="2" xl="2"
                  style ="display:block"
                  v-on:click="$router.push({path:'/Winery/WineShow', query: {winery_id:$route.query.winery_id,wine_id:wine._id }})"
                > 
                  <b-row style="width:100%">
                    <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                      <b-img center v-if="wine.image_files[0]"
                        style="height:100%;width:auto;position:absolute;right:0px"
                        v-bind:src="wine.image_files[0].dataURL" 
                      ></b-img>
                      <b-img center v-else
                        style="height:100%;width:auto;position:absolute;right:0px"
                        :src="require('@/assets/img/bottle.png')"
                      ></b-img>
                    </b-col>
                    <b-col cols="9" class="d-table-cell">
                      <p class="w-title">{{wine.name}}</p>
                      <p class="w-subtitle" v-if="wine.type">{{wine.type}}</p>
                      <hr style="margin:5px 0 5px 0">
                      <p class="w-text" v-if="wine.appellation">{{wine.appellation}}</p>
                      <p class="w-text" v-if="wine.classification">{{wine.classification}}</p>
                      <p class="w-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                    </b-col>
                  </b-row>
                
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col>
                  <p class="preview-text text-center">{{winery.address1}}</p>
                  <p class="preview-text text-center">{{winery.address2}}</p>
                  <p class="preview-text text-center">{{winery.zip}} {{winery.city}}</p>
                  <p class="preview-text text-center">{{winery.country}}</p>
                  <p class="preview-text text-center">{{winery.phone}}</p>
                  <p class="preview-text text-center">{{winery.email}}</p>
                </b-col>           
              </b-row>     
              <hr>
              <b-row class="justify-content-center">
                <p class="w-subtitle text-center" >This page is now available on </p>
              </b-row>
              <b-row class="justify-content-center">  
                <p class="w-subtitle text-center w-cursor-click" v-on:click="gotoWebsite()"> <b>https://www.wynemaker.com/{{winery.webname}}</b></p>
              </b-row>
              <hr>
              <b-row class="justify-content-center">
                <p class="w-cursor-click" v-if="winery.webpage !='' && typeof winery.webpage != 'undefined'" v-on:click="gotoWebpage()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/web.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> website</span></b></p>
                <p class="w-cursor-click" v-if="winery.facebook !='' && typeof winery.facebook != 'undefined'" v-on:click="gotoFacebook()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/facebook.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> facebook</span></b></p>
                <p class="w-cursor-click" v-if="winery.instagram != '' && typeof winery.instagram != 'undefined'" v-on:click="gotoInstagram()"  style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/instagram.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> instagram</span></b></p>
                <p class="w-cursor-click" v-if="winery.twitter != '' && typeof winery.twitter != 'undefined'" v-on:click="gotoTwitter()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/twitter.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> twitter</span></b></p>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import makeWebname from '../assets/js/makeWebname.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import Wineries from '../Wineries.js'
  import Wines from '../Wines.js'

  export default {
    name: 'WineryPreview',
    components: {
      NoAccess,
      Menu
    },
    computed : {
      inSession() {
        return this.$store.state.inSession;
      }
    },
    data() {
      return {
        debug: false,
        
        isLoading: true,

        winery: {
          name : '',
          description: '',
          webpage: '',
          image_files:[],
          wine_ids:[],
        },
        wines:[],

        isValid : true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WineryOveriew in mounted() : Fetching winery from DB '+ this.$route.query.winery_id);

      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.winery = response;
        delete this.winery._id;
        if (this.debug) console.log( "WineryOverview in mounted(): Winery "+ this.winery.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log( "WineryOverview in mounted(): catch await Wineries.getone ERROR : ", error);
      })

      if (typeof this.winery.wine_ids === "undefined" || this.winery.wine_ids === null) {
        this.winery.wine_ids = [];
      }
      for (var i=0; i < this.winery.wine_ids.length; i ++) {
        if (typeof this.winery.wine_ids[i] != "undefined" && this.winery.wine_ids[i] != null) {
          await Wines.getone(this.winery.wine_ids[i])
          .then(response=> {
            this.wines.push(response)
            if (this.debug) console.log('WineryOverview in mounted() : Wine with id= ', this.wines[i], ' succesfully fetched from DB')
          })
          .catch(error=>{
            if (this.debug) console.log('WineryOverview in mounted() : catch await Wines.getone ERROR : ' + error);
          })
        } else {
          if (this.debug) console.log('WineryOverview in mounted() : ERROR wine_ids[',i,'] = ', this.winery.wine_ids[i]);
        }
      }

      this.isLoading = false;
    },
    methods: {
      onPrevious() {
        this.$store.commit('setPage','Wines')
        this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id }} );
      },
      gotoWebsite() {
        window.open('https://www.wynemaker.com/'+this.winery.webname, '_blank');
      },
      gotoWebpage() {
        if (this.winery.webpage.substr(0,4) != "http") {
          this.winery.webpage = "http://" + this.winery.webpage
        }
        window.open(this.winery.webpage, '_blank');
      },
      gotoFacebook() {
        if (this.winery.facebook.substr(0,4) != "http") {
          this.winery.facebook = "http://" + this.winery.facebook
        }
        window.open(this.winery.facebook, '_blank');
      },
      gotoInstagram() {
        if (this.winery.instagram.substr(0,4) != "http") {
          this.winery.instagram = "http://" + this.winery.instagram
        }
        window.open(this.winery.instagram, '_blank');
      },
      gotoTwitter() {
        if (this.winery.twitter.substr(0,1) == "@") {
          this.winery.twitter = "https://twitter.com/" + makeWebname(this.winery.twitter)
        }
        window.open(this.winery.twitter, '_blank');
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.over {
    position: relative;
    width:90%;
    top: -50px;
    left: 45%;
    z-index: 3;
    color: black;
    padding: 3% 5% 5% 5%;
    background-color:white;
    border-radius: 1%;
      -webkit-transform: translate3d(-50%,0,0);
         -moz-transform: translate3d(-50%,0,0);
          -ms-transform: translate3d(-50%,0,0);
           -o-transform: translate3d(-50%,0,0);
              transform: translate3d(-50%,0,0);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
       -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
}

.main-title {
  font-family: 'Segoe UI';
  font-size: 44px;
  font-weight: bolder;
  color: rgb(112,112,112);
}

.main-text {
  font-family: 'Segoe UI';
  font-size: 16px;
  color: rgb(112,112,112);
}

.w-picture{
  height: 200px;
  width: auto;
}
.w-detail {

}
.w-title {
  font-size: 18px;
  margin: 10px 0 0 0;
}

.w-subtitle {
  font-size:14px;
  margin:0.5vm;
}

.w-text {
  margin:0;
  font-size: 12px;
}

.w-smalltext {
  padding-top : 10px;
  font-size: 10px;
}

</style> 