<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineryContactMobile  v-if="matches" />
    <WineryContactDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineryContactMobile from "./WineryContact_Mobile.vue";
  import WineryContactDesktop from "./WineryContact_Desktop.vue";
 
  export default {
    name: 'WineryContact',
    components: {
      MatchMedia,
      WineryContactMobile,
      WineryContactDesktop,
    },
  }
</script> 