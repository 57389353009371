// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/wines/'
// const url = WEBSERVER + ':' + PORT + '/api/wines/'

const url = API + '/wines/'
const debug = false;

class Wines {
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(wine=> ({
                        ...wine,
                        name: wine.name
                    }))
                );
                if (debug) console.log("Wines.get: data = ", data)
            } catch(err) {
                reject(err);
            }

        })
    }

    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Wines.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Wines.getone: ',error)
            })
    }

    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                if (debug) console.log('Wines.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Wines.create: ',error)
            })
    }

    static update(id,postdata) {
        if (debug) console.log('Wines.update: id=',id)
        if (debug) console.log('Wines.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                if (debug) console.log('Wines.update: ',response)
            })
            .catch(error => {
                console.log('Wines.update: ',error)
            })
    }

    static delete(id) {
        return axios.delete(`${url}${id}`)
    }
}

export default Wines