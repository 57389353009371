<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container v-if="this.inSession" fluid>
        <b-row>
          <b-col sm="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col sm="10" style="padding:0" class="text-center">
            <b-row>
              <div class="w-box" style="width: 80%; margin: 10vh 10vw 0 10vw !important">
                <b-row>
                  <b-col md="4">
                    <b-row class="justify-content-center" v-if="wine.image_files.length > 0">
                      <b-carousel
                      id="carousel-fade"
                      :interval="4000"
                      controls
                      indicators
                      background="#ffffff"
                      img-width="100"
                      img-height="300"
                      style="width:max-content;height:auto"
                      >
                        <b-carousel-slide v-for="image_file in wine.image_files" v-bind:key="image_file.dataURL">
                          <template v-slot:img>
                            <b-img   
                              v-bind:src="image_file.dataURL"
                              style="height:50vw;position:relative;right:0px"
                            />
                          </template>
                        </b-carousel-slide>
                      </b-carousel>
                    </b-row>
                    <b-row v-else>
                      <b-img center
                        style="height:50vw;position:relative;right:0px"
                        :src="require('@/assets/img/bottle.png')"
                      ></b-img>
                    </b-row> 
                  </b-col>
                  <b-col md="8">
                    <div style="height:5vh">
                    </div>
                    <div>
                      <div>
                        <h1 class="preview-title">{{this.wine.name}}</h1> 
                        <h5 class="preview-subtitle">{{this.wine.type}}</h5>
                        <hr>
                        <p class="preview-text">{{this.wine.region}}, {{this.wine.country}}</p>
                        <p class="preview-text">{{this.wine.appellation}}, {{this.wine.classification}}</p>

                      </div>
                      <div v-for="(value, key) in this.wine" v-bind:key="key">
                        <div v-if="key==='tasting_notes' && value.length>0">
                          <hr>
                          <p  class="w-text" style="font-style:italic">{{value}}</p>
                        </div>  
                        <div v-if="key==='grapes' || key==='vines' || key==='vinification' || key === 'yields'">
                          <p  class="w-text" v-if="value.length>0"><b>{{ key }}</b> : {{value}}</p>
                        </div>
                      </div>
                      <hr>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-row>
              <div class="e-box" style="margin-left:10vw; padding-left:0 !important">
                <b-col style="padding-left:0">
                  <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
                </b-col>
              </div>
            </b-row>
          </b-col>
        </b-row>   
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>
<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import Wines from '../Wines.js'
  
  export default {
    name: 'WineShows',
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        isLoading : true,
        wine: {},
      }
    },
  async mounted(){
    if (this.debug) console.log('WineShow in mounted: START');
    
    await Wines.getone(this.$route.query.wine_id)
    .then(response => {
      this.wine = response
    })
    .catch(error => {
      console.log('WineShow in mounted: Catch await Wines.getone - ' + error);
    })

    this.isLoading = false;
  },
  methods: {
    onBack() {
      this.$router.push({path:'/Winery/Preview', query: {winery_id:this.$route.query.winery_id }})
    }
  }
}
</script>

<style scoped>

</style>