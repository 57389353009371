// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/confirm_maker/';
// const url = WEBSERVER + ':' + PORT + '/api/confirm_maker/';

const url = API + '/confirm_maker/';
const debug = false;

class Confirm {
    static sendEmail(id) {
        if (debug) console.log('Confirm.sendEmail: id=',id)
        return axios.post(`${url}${id}`)
            .then(response => {
                if (debug) console.log('Confirm.sendEmail: ',response)
            })
            .catch(error => {
                console.log('Confirm.sendEmail: ',error)
            })
    }
}

export default Confirm