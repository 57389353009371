<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <div style="height:15vh">
            </div>
            <div class="w-box" style="width:60%;position:relative">
              <div class="e-box text-center" style="position:absolute;width:100%;height:100%">
                <vue-dropzone 
                  id="imgDropzone" ref="imgDropzone" class="winerydropzone" 
                  :options="dropzoneOptions"
                  @vdropzone-thumbnail="vfileAdded" 
                >
                </vue-dropzone>    
              </div>
              <p class="w-subtitle" style="margin-left:15px;margin-right:15px; text-align:right;">winery pictures</p>

              <b-row style="margin-left:30px;margin-right:30px;margin-bottom:20px; height:60%">
                <div v-if="form.image_files[0]" class="p-box" style="width:100%;border-style:none;position:relative"> 
                  <b-img  
                    v-bind:src="form.image_files[0].dataURL"
                    style="width:100%"
                  >
                  </b-img>
                  <div v-on:click="onDeletePicture(0)" class="delete-picture">
                    <b-img
                      :src="require('@/assets/img/delete-picture.png')"
                      style="width:100%;height:100%;border-radius:50%;border-style:none"
                    ></b-img>
                  </div>
                </div>
                <div v-else class="p-box" style="width:100%">
                  <b-img
                    :src="require('@/assets/img/winery-picture-plus.png')"
                    style="width:100%"
                  >
                  </b-img>
                </div>
              </b-row>

              <b-row style="margin-left:30px;margin-right:30px;margin-bottom:20px; height:60%">
                <div v-if="form.image_files[1]" class="p-box" style="width:100%;border-style:none;position:relative"> 
                  <b-img  
                    v-bind:src="form.image_files[1].dataURL"
                    style="width:100%"
                  >
                  </b-img>
                  <div v-on:click="onDeletePicture(1)" class="delete-picture">
                    <img
                      :src="require('@/assets/img/delete-picture.png')"
                      style="width:100%;height:100%;border-radius:50%;border-style:none"
                    />
                  </div>
                </div>
                <div v-else class="p-box" style="width:100%">
                  <b-img
                    :src="require('@/assets/img/winery-picture-plus.png')"
                    style="width:100%"
                  >
                  </b-img>
                </div>
              </b-row>

              <b-row style="margin-left:30px;margin-right:30px;margin-bottom:20px; height:60%">
                <div v-if="form.image_files[2]" class="p-box" style="width:100%;border-style:none;position:relative"> 
                  <b-img  
                    v-bind:src="form.image_files[2].dataURL"
                    style="width:100%"
                  >
                  </b-img>
                  <div v-on:click="onDeletePicture(2)" class="delete-picture">
                    <img
                      :src="require('@/assets/img/delete-picture.png')"
                      style="width:100%;height:100%;border-radius:50%;border-style:none"
                    />
                  </div>
                </div>
                <div v-else class="p-box" style="width:100%">
                  <b-img
                    :src="require('@/assets/img/winery-picture-plus.png')"
                    style="width:100%"
                  >
                  </b-img>
                </div>
              </b-row>
            </div>
            <div style="width:60%">
            <b-row>
              <b-col  class="text-left">
                <b-button v-on:click="onPrevious()" class="w-button-pink">previous</b-button>
              </b-col>
              <b-col class="text-right">
                <b-button v-on:click="onNext()" class="w-button-green">next</b-button>
              </b-col>
            </b-row>
            <br>
          </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import NoAccess from './NoAccess.vue';
  import Menu from './MenuDesktop.vue';

  import Wineries from '../Wineries.js';

  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  export default {
    name: 'WineryPicture',
    components: {
      NoAccess,
      Menu,
      vueDropzone: vue2Dropzone
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        form: {
          image_files: []
        },

        isValid : true,
        hasChanged : false,

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 10,
        },
        dropzoneFilled: false,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WineryPictures in mounted(): Fetching winery from DB '+ this.$route.query.winery_id);
      
      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.form = response;
        delete this.form._id;
        if (this.debug) console.log( "WineryPictures in mounted(): Winery "+ this.form.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log( "WineryPictures in mounted(): catch await Wineries.getone - ERROR : ", error);
      })

      this.isLoading = false;
    },
    // updated() {
    //   if(!this.dropzoneFilled) {
    //     try {
    //       if (typeof this.form.image_files !== 'undefined') {
    //         for (var i=0; i < this.form.image_files.length;i++) {
    //           var dataURL = this.form.image_files[i].dataURL
    //           var filename = this.form.image_files[i].upload.filename
    //           var extension = filename.substring(filename.indexOf(".")+1)
    //           var mime_type = "___"
    //           var end2 = dataURL.substring(dataURL.length-2)
    //           var y = 0
    //           if ( end2 === "==") {
    //             y = 2
    //           } else {
    //             y = 1
    //           }
    //           var size = dataURL.length*3/4 - y
    //           if (extension == "jpg" || extension == "jpeg") {
    //             mime_type = "image/jpeg"
    //           } else if (extension == "png") {
    //             mime_type = "image/jpeg"
    //           }   
    //           var file = { name: filename, size: size, type: mime_type, dataURL: dataURL};

    //           this.$refs.imgDropzone.manuallyAddFile(file, dataURL);
    //           this.$refs.imgDropzone.dropzone.emit('thumbnail', file, file.dataURL)
    //           this.$refs.imgDropzone.dropzone.emit('complete', file)
    //         }
    //       }
    //       this.dropzoneFilled = true;
    //     } catch(err) {
    //       console.log(err.message);
    //     }
    //   }
    // },
    methods: {
      async onSave() {
        if(this.isValid){
          //THIS IS WHERE YOU SUBMIT DATA TO SERVER
          await Wineries.update(this.$route.query.winery_id,this.form)
          console.log("WineryGeneral in onSave(): Submitted : ", this.form)
          this.hasChanged = false
        }
        return 'done'  
      },
      async onNext() {
        if (this.hasChanged) await this.onSave();
        this.$store.commit('setPage','Wines')
        this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id }} );
      },
      async onPrevious() {
        if (this.hasChanged) await this.onSave();
          this.$store.commit('setPage','SocialMedia')
          this.$router.push({path:'/Winery/SocialMedia', query: {winery_id:this.$route.query.winery_id }} );
      },

      async vfileAdded(new_image_file) {
        if (this.debug) console.log("vfileAdded : winery picture URL: ", new_image_file);
        let org_base64 = new_image_file.dataURL;
        let cc_base64 = await cc64(org_base64,5,2);
        new_image_file.dataURL = cc_base64;
        this.form.image_files.push(new_image_file);
        if (this.form.image_files.length > 3) {
          this.form.image_files.shift();
        }
        this.$refs.imgDropzone.removeFile(new_image_file);
        this.hasChanged = true;
        this.onSave();
      },

      onDeletePicture(i){
        this.form.image_files.splice(i, 1);
        this.hasChanged = true;
        this.onSave();
      },

      template: function () {
         return `<div class="dz-preview">
                </div>`;
      },
    }
  }
</script>

<style>
</style> 

<style scoped>
/* Custom Dropzone */
.winerydropzone {
  position: relative;
  top:-20px;
  left:-20px;
  width:100%;
  height:100%;

  background-color: transparent;
  border-style: none;
  border-radius: 5px;

  color:#696969;

  transition: background-color .2s linear;
}
</style>