<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="9" style="padding:0" align="center">
            <div style="height:15vh">
            </div>
            <div class="w-box" style="width:60%">
              <p class="w-subtitle" style="text-align:right">contact</p>
              <b-form autocomplete="false">
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-address1">address line 1</label>
                  <b-form-input
                  id="input-address1"
                  class="w-input"
                  v-model="form.address1"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.address1Valid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{address1Feedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-address2">address line 2</label>
                  <b-form-input
                  id="input-address2"
                  class="w-input"
                  v-model="form.address2"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.address2Valid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{address2Feedback}}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-row>
                  <b-col xs="6">
                     <b-form-group style="text-align:left">
                      <label class="w-label" for="input-zip">zip</label>
                      <b-form-input
                      id="input-zip"
                      class="w-input"
                      v-model="form.zip"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.zipValid}"
                      aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{zipFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col xs="6">
                    <b-form-group style="text-align:left">
                      <label class="w-label w-required" for="input-city">city</label>
                      <b-form-input
                      id="input-city"
                      class="w-input"
                      v-model="form.city"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.cityValid}"
                      aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{cityFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <b-form-group style="text-align:left">
                  <label class="w-label w-required" for="countryList">country</label>        
                  <model-select
                    class="w-select"
                    :options="countries"
                    v-model="form.country_id"
                    placeholder="Select country"
                    @input="inputCountry"
                  >
                  </model-select>
                </b-form-group>
                
                <b-row>
                  <b-col xs="4">
                     <b-form-group style="text-align:left">
                      <label class="w-label" for="input-phone">phone</label>
                      <b-form-input
                      id="input-phone"
                      class="w-input"
                      v-model="form.phone"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.phoneValid}"
                      aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{phoneFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                  <b-col xs="8">
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-email">email</label>
                      <b-form-input
                      id="input-email"
                      class="w-input"
                      v-model="form.email"
                      v-bind:class="{'form-control':true, 'is-invalid' : !this.emailValid}"
                      aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        {{emailFeedback}}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-form>
            </div>
            <div style="width:60%">
              <b-row>
                <b-col  class="text-left">
                  <b-button v-on:click="onPrevious()" class="w-button-pink">previous</b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button v-on:click="onNext()" class="w-button-green">next</b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import { ModelSelect } from 'vue-search-select';

  import Wineries from '../Wineries.js'
  import Countries from '../Countries.js'

  export default {
    name: 'WineryContact',
    components: {
      NoAccess,
      Menu,
      ModelSelect,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        form: {
          address1 : '',
          address2 : '',
          zip : '',
          city : '',
          country : '',

          phone : '',
          email : '',
        },

        countries: [],

        // Validation Logic
        address1Valid : true,
        address1Feedback : '',
        address2Valid : true,
        address2Feedback : '',
        zipValid : true,
        zipFeedback : '',
        cityValid : true,
        cityFeedback : '',
        countryValid : true,
        countryFeedback : '',

        phoneValid : true,
        phoneFeedback : '',
        emailValid : true,
        emailFeedback : '',

        formValid : true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WineryContact in mounted(): Fetching winery from DB '+ this.$route.query.winery_id);
      
      await Countries.get()
      .then(response=>{
        for (let i = 0 ; i < response.length ; i++) {
          this.countries.push({value:response[i]._id,text:response[i].name})
        }
        this.countries.sort((a,b)=>(a.text>b.text)?1:-1);
        if (this.debug) console.log( "WineryContact in mounted: Countries ", response, " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log("WineryContact in mounted: catch await Countries.get() - error : ", error);
      })

      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.form = response;
        delete this.form._id;
        if (this.debug) console.log( "WineryContact in mounted(): Winery "+ this.form.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log( "WineryContact in mounted(): catch await Wineries.getone - ERROR : ", error);
      })

      this.isLoading = false;
    },
    methods: {
      validate(){
        this.formValid = true;
      },  
      async onSave() {
        if(this.formValid){
          //THIS IS WHERE YOU SUBMIT DATA TO SERVER
          await Wineries.update(this.$route.query.winery_id,this.form)
          if (this.debug) console.log("WineryContact in onSave(): Submitted : ", this.form)
        }
        return 'done' 
      },
      async onNext() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WineryContact in onNext():", response)
          this.$store.commit('setPage','SocialMedia')
          this.$router.push({path:'/Winery/SocialMedia', query: {winery_id:this.$route.query.winery_id }} );
        })
        
      },
      async onPrevious() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WineryContact in onPrevious():", response)
          this.$store.commit('setPage','General')
          this.$router.push({path:'/Winery/General', query: {winery_id:this.$route.query.winery_id }} );
        }) 
      },
      onReset() {
        this.form.address1 = '';
        this.form.address2 = '';
        this.form.zip = '';
        this.form.city = '';
        this.form.country = '';

        this.form.phone = '';
        this.form.email = '';
        this.form.webpage = '';

        this.form.facebook = '';
        this.form.twitter = '';
        this.form.instagram = '';
      },
      
      async inputCountry(value) {
        this.form.country_id = value;
        for (let i=0; i< this.countries.length; i++) {
          if (this.countries[i].value === value) {
            this.form.country = this.countries[i].text;
          }
        }
        
        if (this.debug) console.log("WineryContact in inputCountry - country_id = ",this.form.country_id);
        if (this.debug) console.log("WineryContact in inputCountry - country = ",this.form.country);
      },
    }
  }
</script>
