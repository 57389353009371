<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title">Welcome to <span style="color: rgb(195,147,130)">WYNE</span>MAKER</h1>
            <p class="main-subtitle">make yourself known to all wine lovers</p>
          </div> 
        </b-col>
      </b-row> 
      <b-row>
         <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8">
          <br> 
          <p class="w-title">Our Mission</p>
          <p class="w-text">Our dream is to unify and centralize all wine information controlled by the producer and share it freely with the rest of the world. </p>
          <p class="w-text"><b>Therefore we offer you a free tool to make your winery and wines known to the wine loving world.</b></p>
          <p class="w-text">All information entered in the WyneMaker database is shared and will be publicly available for free to feed other applications that need information about your winery; like WyneCellar, for example, which is a tool to manage your private winecellar.</p> 
          <p class="w-text">But beware, WyneMaker is for professional winemakers only!</p>
          <p class="w-text">The information in the database is solely controlled by producers and by producers only. Therefore, by entering information in WyneMaker you certify that you are a legal spokesperson for your winery and consent to share your winery and wine data.</p>
          <p class="w-text">Although WyneMaker has been developed with the uttermost care, we explicitly inform you that it is still under construction and might undergo some changes.</p>
          <p class="w-text">I hereby declare I fully qualify as a wine producer.</p>  
            <b-form-checkbox 
              id="showToggle"
              v-model="user.showWelcome"
            >
            <p class="w-label" for="showToggle">show this page again</p>        
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right"> 
          <b-button v-on:click="gotoStart()" class="w-button-green">I agree</b-button>
        </b-col>
      </b-row>
      <br>  
    </div>
  </div>
</template>

<script>
  import Users from '../Users.js';
  
  export default {
    name: 'WineryWelcome',
     computed : {
      inSession() {
        return this.$store.state.inSession
      },
      currentUser() {
        return this.$store.state.user
      },
      currentProfilePicture() {
        return this.$store.state.profile_picture
      },
      currentUserID() {
        return this.$store.state.user_id
      },
      previousPage() {
        return this.$store.state.previous_page
      },
    },
    data() {
      return {
        debug : false, 
        isLoading : true,

        user: {},
        user_id:0,
      }
    }, 
    mounted() {
      this.user_id = this.currentUserID;
      this.user = this.currentUser;
      this.isLoading = false
    },
    methods: {
      gotoStart() {
        this.onUpdate()
        this.$store.commit('setPage','General')
        this.$router.push({path:'/Winery/General', query: {winery_id:this.$route.query.winery_id }} );
      },
      async onUpdate() {
        await Users.update(this.user_id,this.user)
        .then(response => {
          if (this.debug) console.log("WineryWelcome in onUpdate : User ", response , " succesfully updated!")
          this.$store.commit('setUser',this.user)
        })
        .catch(error => {
          if (this.debug) console.log("WineryWelcome in onUpdate : Catch is await Users error:", error)
        })

        try {
          this.$store.commit('setUser',this.user)
          this.$store.commit('setUserID',this.user_id)
        } catch(err) {
          alert("WineryWelcome in onUpdate : Catch this.$store.commit ", err)
        }
      }
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style> 