<template>
  <div class="full">
    <b-navbar toggleable type="dark" variant="info">
      <b-navbar-brand>WyneMaker</b-navbar-brand>
      <b-navbar-brand align="right">Add Winery to Registration</b-navbar-brand>
    </b-navbar>
  <b-container class="space-2 space-3--lg">
    <br>
    <b-form>
      <b-row>
        <b-col sm="7" lg ="4" class="d-table">
          <div style="text-align: center" v-if="winery.image_files.length > 0">
            <img 
              class="fluid-grow"
              style="border-radius: 25px;object-fit: cover"
              width="300"
              height="200"
              v-bind:src="winery.image_files[0].dataURL"
              alt="Winery Picture comes HERE"
            />
          </div>
          <div style="text-align: center" v-else>
            <img
              class="fluid-grow"
              style="border-radius: 25px;object-fit: cover"
              width="300"
              height="200"
              :src="require('@/assets/img/winery-picture.png')"
              alt="Winery Picture comes HERE"
            />
          </div>
          <br>
          
          <!-- 
          <vue-dropzone 
            ref="imgDropzone" id="dropzone" 
            :options="dropzoneOptions"
            @vdropzone-thumbnail="vfileAdded" 
            @vdropzone-removed-file="vfileRemoved"
          >
          </vue-dropzone>
          <p style="font-size:0.8rem" class="text-center">The best pictures have an aspect ratio of 3:2<br>(eg 300x200, 600x6400)</p> 
          -->

          <b-row style="margin-left:0;margin-right:0">
            <vue-dropzone  ref="imgDropzone" id="customdropzone" 
              :options="dropzoneOptions" 
              :useCustomSlot=true 
              :include-styling="false"
              v-on:vdropzone-thumbnail="thumbnail" 
              @vdropzone-thumbnail="vfileAdded" 
              @vdropzone-removed-file="vfileRemoved"
            >
            <div class="dz-message" style="text-align:center">Drag and drop your pictures here or click to select file.</div>
          </vue-dropzone>
          </b-row>
          <b-row style="margin-left:0;margin-right:0;text-align:center">
            <p>best aspect ratio is 3/2 - your pictures will be cropped anyway</p>
          </b-row>
        </b-col>
        <b-col sm="5" lg ="8" class="d-table">
          <h1>Hello {{user.firstname}}</h1>
          <p>Welcome back. </p> 
          <p>You are registered for the WyneCellar application, as owner of the cellar you called "{{cellar.name}}".</p>
          <p>We understand you want to add access to the WyneMaker application to manage your produced wines too.</p>
          <p>Please note that WyneMaker is for the professional winery owners ONLY.</p> 
          <p>Extra controls will apply.</p>
          <hr>
          <b-form-group> <!-- NAME -->
            <b-row style="margin-left:0">
              <b-col md="4" class="text-left align-self-center" >
                  <label>NAME:</label>
              </b-col>
              <b-col md="8">
                <label>{{user.firstname}} {{user.lastname}}</label>
              </b-col>
            </b-row>
          </b-form-group> 

          <b-form-group> <!-- EMAIL -->
            <b-row style="margin-left:0">
              <b-col md="4" class="text-left align-self-center">
                  <label>EMAIL:</label>
              </b-col>
              <b-col md="8">
                <label>{{user.email}}</label>
              </b-col>
            </b-row>
          </b-form-group>    
        
          <b-form-group> <!-- CELLAR NAME -->
            <b-row style="margin-left:0">
              <b-col md="4" class="text-left align-self-center">
                  <label>CELLAR NAME:</label>
              </b-col>
              <b-col md="8">
                <label>{{cellar.name}}</label>
              </b-col>
            </b-row>
          </b-form-group>

          <hr>

          <b-form-group> <!-- WINERY NAME -->
            <b-row style="margin-left:0">
              <b-col md="4" class="text-left align-self-center">
                  <label for="input-name">WINERY NAME:</label>
              </b-col>
              <b-col md="8">
                <b-form-input
                id="input-wineryname"
                class = "wm-input"
                v-model="winery.name"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.winerynameValid}"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Enter winery name"
                ></b-form-input>

                <b-form-invalid-feedback id="input-live-feedback">
                  {{winerynameFeedback}}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
          </b-form-group>
          
          <hr>
          
          <b-row style="margin-right:0">
            <b-col class="text-right">
              <b-button class="w-button" v-on:click="onRegister()">Register</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
  </div>
</template>

<script>
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';
  import Users from '../Users.js';
  import Mail from '../Mail.js';
  import Cellars from '../Cellars.js';
  import Wineries from '../Wineries.js';

  export default {
    name: 'AddWinery2Registration',
    components:{
      vueDropzone: vue2Dropzone,
    },
    data() {
      return {
        debug: false,
        //Working Objects
        user: {
            firstname : '',
            lastname : '',
            email : '',
            hashed_password : '',
            cellar_id : '',
            image_files : [],
            isConfirmed : false,
            hasAgreedTerms : false
        },
        user_id:0,
        
        winery: {
            name : '',
            user_id : '',
            wine_ids:[],
            image_files : [],
        },
        winery_id:0,

        cellar: {},
        cellar_id:0,

        // Validation Logic
        winerynameValid : true,
        winerynameFeedback : '',

        formValid : false,

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          addRemoveLinks: true,
          thumbnailWidth: 100,
          thumbnailHeight: 100,
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : 'Drop you Winery Picture here.',
          maxFilesize: 10,
          headers: { "My-Awesome-Header": "header value" }
        },
        dropzoneFilled: false,
      }
    },
    async mounted() {
      this.user_id = this.$route.query.user_id;
      
      if (this.user_id == null) return;

      await Users.getone(this.user_id)
      .then(response=>{
        if (this.debug) console.log("AddWinery2Registration in mounted: user succesfully retrieved form DB - ", response)
        this.user = response;
        delete this.user._id;
      })
      .catch(error=>{
        if (this.debug) console.log("AddWinery2Registration in mounted: catch Users.getone - ", error)
      })

      this.cellar_id  = this.user.cellar_id
      if ( this.cellar_id != null) {
        await Cellars.getone(this.cellar_id)
        .then(response=>{
          if (this.debug) console.log("AddWinery2Registration in mounted : vellar succesfully retrieved form DB - ", response)
          this.cellar = response;
          delete this.Cellars._id;
        })
        .catch(error=> {
          if (this.debug) console.log("AddWinery2Registration in mounted : catch Cellars.getone - ", error)
        })
      } 
    },
    methods: {
        async validate(){
          //chack validity of name fields
          if (this.winery.name.length < 3) {
            this.winerynameValid = false
            this.winerynameFeedback = 'Winery Name should be at least 3 characters long'
          } else {
             this.winerynameValid = true
          }
          this.formValid = this.winerynameValid
          if (this.debug) console.log("AddWinery2Registration in validate(): form.valid=",this.formValid);
        },
        async onRegister() {
          if (this.debug) console.log("AddWinery2Registration in onRegister: Register pressed");

          await this.validate()
          if (this.debug) console.log("AddWinery2Registration in onRegister: form.valid=",this.formValid);
          if (this.formValid) {
            // HERE COMES THE DB STUFF
            this.winery.user_id = this.user_id
            if (this.debug) console.log("TMP this.winery - ", this.winery)
            await Wineries.create(this.winery)
            .then(response=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister: Cellar succesfully mounted - ",response);
              this.user.winery_id = response
            })
            .catch(error=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister: catch Cellar.create - ",error);
            })

            await Users.update(this.user_id,this.user)
            .then(response=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister: User succesfully updated - ",response);
            })
            .catch(error=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister: catch User.update - ",error);
            })

            let this_html = ""
            this_html += "<p>Hi " + this.user.firstname + "</p>"
            this_html += "<p>Next to your existing subscription to WyneCellar, you are now subscribed to WyneMaker.</p>"
            this_html += "<p>Enjoy !</p>"
            this_html += "<p>The Wyne Team</p>"
    
            let postdata = {};
            postdata.email = this.user.email;
            postdata.subject = "Welcome to WyneMaker";
            postdata.html = this_html;
            if (this.debug) console.log("AddWinery2Registration in onRegister - Mailing postdata = ", JSON.stringify(postdata))

            await Mail.sendMaker(postdata)
            .then(response=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister - successfully transfered postdata to Mail - from Cellar ", response);

            })
            .catch(error=>{
              if (this.debug) console.log("AddWinery2Registration in onRegister - catch Mail.sendMaker - ", error)
              return
            })

            await this.$store.commit('setUser',this.user)
            await this.$store.commit('setUserID',this.user_id)
            await this.$store.commit('setProfilePicture',this.user.profile_picture)

            await this.$store.commit('login');
            await this.$store.commit('setPage','General');
            await this.$router.push({path:'/Winery/General', query: {user_id: this.user_id, winery_id:this.user.winery_id }});
          } 
        },
      onReset() {
        // Reset our form values
        if ( this.winery.image_files.length > 0) {
          var old_image_file = this.winery.image_files.pop()
          this.$refs.imgDropzone.removeFile(old_image_file)
        }
        this.winery.image_files = []
        
        this.winery.name = ''
      },

      // Dropzone stuff
      vfileAdded(new_image_file) {
        if (this.debug) console.log("profile picture  URL: " + new_image_file.dataURL)
        if (this.winery.image_files.length > 0) {
          var old_image_file = this.cellar.image_files.pop()
          this.$refs.imgDropzone.removeFile(old_image_file)
        }
        this.winery.image_files.push(new_image_file)
      },
      vfileRemoved(image_file) {
        this.winery.image_files.pop(image_file);
      },
      template: function () {
         return `<div class="dz-preview dz-file-preview">
                  <div class="dz-image">
                      <div class="dz-thumbnail" data-dz-thumbnail-bg></div>
                      <div class="thumbnail-overlay">
                        <div class="text" style="text-align: center; margin-top: 30px" data-dz-remove>remove</div>
                      </div>
                  </div>
                  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                  <div class="dz-error-message"><span data-dz-errormessage></span></div>
                  <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                  <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                </div>`;
      },
      thumbnail: function(file, dataUrl) {
        var j, len, ref, thumbnailElement;
        if (file.previewElement) {
            file.previewElement.classList.remove("dz-file-preview");
            ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
            for (j = 0, len = ref.length; j < len; j++) {
                thumbnailElement = ref[j];
                thumbnailElement.alt = file.name;
                thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }
            return setTimeout(((function() {
                return function() {
                    return file.previewElement.classList.add("dz-image-preview");
                };
            })(this)), 1);
        }
      } 
    }
  }
</script>

<style scoped>
.bg-info {
    background-color: rgb(234,202,81) !important;
}
</style>

<style>
@import url("../assets/styles/wynestyle.css");
</style>