<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col style="padding:0" align="center">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/menu.png')"
                v-b-toggle.menu-sidebar
              >
            </div>
            <hr>
            <div class="m-box">
              <p class="w-subtitle" style="text-align:right">web and social media</p>
              <b-form autocomplete="false">
                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-webpage">webpage</label>
                  <b-form-input
                  id="input-webpage"
                  class="w-input"
                  v-model="form.webpage"
                  v-bind:class="{'is-invalid' : !this.webpageValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{webpageFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-facebook">facebook</label>
                  <b-form-input
                  id="input-facebook"
                  class="w-input"
                  v-model="form.facebook"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.facebookValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{facebookFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-twitter">twitter</label>
                  <b-form-input
                  id="input-twitter"
                  class="w-input"
                  v-model="form.twitter"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.twitterValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{twitterFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-instagram">instagram</label>
                  <b-form-input
                  id="input-instagram"
                  class="w-input"
                  v-model="form.instagram"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.instagramValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{instagramFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

              </b-form>
            </div>
            <div class="m-box">
              <b-button v-on:click="onNext()" class="w-button-green-full">next</b-button>
              <b-button v-on:click="onPrevious()" class="w-button-pink-full" style="margin-top:10px">previous</b-button>
            </div>
          </b-col>
        </b-row>
        <b-sidebar 
          id="menu-sidebar" 
          backdrop
          backdrop-variant="dark" 
          no-header
          shadow
        >
          <template #default="{ hide }">
            <div class="menu-top" style="text-align:center">
              <p><b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</p>
            </div>
            <div>
              <img class="menu-overlay"
                :src="require('@/assets/img/close.png')"
                @click="hide"
              >
            </div>
            <hr>
            <Menu/>
          </template>
        </b-sidebar>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import NoAccess from './NoAccess.vue'
  import Menu from './MenuMobile.vue'

  import Wineries from '../Wineries.js'

  export default {
    name: 'WinerySocialMedia',
    components: {
      NoAccess,
      Menu,
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        form: {
          address1 : '',
          address2 : '',
          zip : '',
          city : '',
          country : '',

          phone : '',
          email : '',
        },

        countries: [],

        // Validation Logic
        webpageValid : true,
        webpageFeedback : '',
        facebookValid : true,
        facebookFeedback : '',
        twitterValid : true,
        twitterFeedback : '',
        instagramValid : true,
        instagramFeedback : '',

        formValid : true,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WinerySocialMedia in mounted(): Fetching winery from DB '+ this.$route.query.winery_id);
      
      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.form = response;
        delete this.form._id;
        if (this.debug) console.log( "WinerySocialMedia in mounted(): Winery "+ this.form.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log( "WinerySocialMedia in mounted(): catch await Wineries.getone - ERROR : ", error);
      })

      this.isLoading = false;
    },
    methods: {
      validate(){
        this.formValid = true;
      },  
      async onSave() {
        if(this.formValid){
          //THIS IS WHERE YOU SUBMIT DATA TO SERVER
          await Wineries.update(this.$route.query.winery_id,this.form)
          if (this.debug) console.log("WinerySocialMedia in onSave(): Submitted : ", this.form)
        }
        return 'done' 
      },
      async onNext() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WinerySocialMedia in onNext():", response)
          this.$store.commit('setPage','Pictures')
          this.$router.push({path:'/Winery/Pictures', query: {winery_id:this.$route.query.winery_id }} );
        })
        
      },
      async onPrevious() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WinerySocialMedia in onPrevious():", response)
          this.$store.commit('setPage','Contact')
          this.$router.push({path:'/Winery/Contact', query: {winery_id:this.$route.query.winery_id }} );
        }) 
      },
      onReset() {
        this.form.address1 = '';
        this.form.address2 = '';
        this.form.zip = '';
        this.form.city = '';
        this.form.country = '';

        this.form.phone = '';
        this.form.email = '';
        this.form.webpage = '';

        this.form.facebook = '';
        this.form.twitter = '';
        this.form.instagram = '';
      },
      
      async inputCountry(value) {
        this.form.country_id = value;
        for (let i=0; i< this.countries.length; i++) {
          if (this.countries[i].value === value) {
            this.form.country = this.countries[i].text;
          }
        }
        
        if (this.debug) console.log("WinerySocialMedia in inputCountry - country_id = ",this.form.country_id);
        if (this.debug) console.log("WinerySocialMedia in inputCountry - country = ",this.form.country);
      },
    }
  }
</script>
