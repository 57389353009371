<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <div v-if="wineryExists">
        <b-container fluid>
          <b-row>
            <b-col style="padding:0" align="center">
              <b-carousel
                id="carousel-fade"
                slide = true
                :interval="4000"
                controls
                background="#fffff0"
                img-width="300"
                img-height="200"
                fade
                style="text-shadow: 1px 1px 2px #333;"
              > 
                <b-carousel-slide v-for="image_file in winery.image_files" v-bind:key="image_file.dataURL">
                  <template v-slot:img>
                    <img
                      class="d-block img-fluid w-100"  
                      width="300"
                      height="200"
                      v-bind:src="image_file.dataURL"
                    >
                  </template>
                </b-carousel-slide>
              </b-carousel>
              <div class="over">
                <b-row>
                  <b-col>
                    <h1 class="preview-title">{{winery.name}}</h1>
                    <p  class="preview-text">{{winery.description}}</p>
                  </b-col>
                </b-row>
                <br>
                <br>
                <p class="preview-subtitle" style="text-align:right!important">our wines</p>
                <b-row class="justify-content-center" style="margin:0"> 
                  <b-col
                    v-for="(wine, index) in wines"
                    v-bind:item="wine"
                    v-bind:index="index"
                    v-bind:key="wine._id" 
                    class="w-tile m-2 d-inline-flex justify-content-center w-cursor-click"
                    xs="12" sm="6" md="4" lg="4" xl="3"
                    style ="display:block; min-width:300px"
                    v-on:click="$router.push({path:'/Wine', query: {wine_id:wine._id }})"
                  > 
                    <b-row style="width:100%">
                      <b-col cols="3" class="justify-content-center" style="height:100%;width:auto;padding:0">
                        <b-img center v-if="wine.image_files[0]"
                          style="height:100%;width:auto;position:absolute;right:0px"
                          v-bind:src="wine.image_files[0].dataURL" 
                        ></b-img>
                        <b-img center v-else
                          style="height:100%;width:auto;position:absolute;right:0px"
                          :src="require('@/assets/img/bottle.png')"
                        ></b-img>
                      </b-col>
                      <b-col cols="9" class="d-table-cell">
                        <p class="w-title">{{wine.name}}</p>
                        <p class="w-subtitle" v-if="wine.type">{{wine.type}}</p>
                        <hr style="margin:5px 0 5px 0">
                        <p class="w-text" v-if="wine.appellation">{{wine.appellation}}</p>
                        <p class="w-text" v-if="wine.classification">{{wine.classification}}</p>
                        <p class="w-smalltext" v-if="wine.grapes">{{wine.grapes}}</p>
                      </b-col>
                    </b-row>
                  
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col>
                    <p class="preview-text text-center">{{winery.address1}}</p>
                    <p class="preview-text text-center">{{winery.address2}}</p>
                    <p class="preview-text text-center">{{winery.zip}} {{winery.city}}</p>
                    <p class="preview-text text-center">{{winery.country}}</p>
                    <p class="preview-text text-center">{{winery.phone}}</p>
                    <p class="preview-text text-center">{{winery.email}}</p>
                  </b-col>           
                </b-row> 
                <hr>
                <b-row class="justify-content-center">
                  <p class="w-cursor-click" v-if="winery.webpage !='' && typeof winery.webpage != 'undefined'" v-on:click="gotoWebpage()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/web.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> website</span></b></p>
                  <p class="w-cursor-click" v-if="winery.facebook !='' && typeof winery.facebook != 'undefined'" v-on:click="gotoFacebook()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/facebook.jpg')"g" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> facebook</span></b></p>
                  <p class="w-cursor-click" v-if="winery.instagram != '' && typeof winery.instagram != 'undefined'" v-on:click="gotoInstagram()"  style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/instagram.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> instagram</span></b></p>
                  <p class="w-cursor-click" v-if="winery.twitter != '' && typeof winery.twitter != 'undefined'" v-on:click="gotoTwitter()" style="float: left;margin: 10px;font-size:11px"><img :src="require('@/assets/img/twitter.jpg')" height="20px" width="20px"><b><span style="color: rgb(114,118,121)"> twitter</span></b></p>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div v-else>
        <br>
        <b-row style="height:40vh;margin:0" class="welcome-picture">
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" style="height:100%">
            <div class="welcome-overlay">
              <h1 class="main-title"><span style="color: rgb(195,147,130)">WYNE</span>MAKER</h1>
              <p class="main-subtitle">PAGE NOT FOUND</p>
            </div> 
          </b-col>
        </b-row> 
        <br>
        <br>
        <br>
        <b-row>
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" class="text-right"> 
            <b-button v-on:click="gotoStart()" class="w-button-green">start</b-button>
          </b-col>
        </b-row>
      </div>  
    </div>
  </div>
</template>

<script>
  import makeWebname from '../assets/js/makeWebname.js';
  import Winery_from_Webname from '../Winery_from_Webname.js';

  import Wineries from '../Wineries.js'
  import Wines from '../Wines.js'
  
  export default {
    name: 'PageNotFound',
    data() {
      return {
        debug: false,

        isLoading : true,

        wineryExists: false,
        webname:'',
        winery_id:0,

        winery: {
          name : '',
          description: '',
          webpage: '',
          image_files:[],
          wine_ids:[],
        },
        wines:[],
      }
    }, 
    async mounted() {
      this.webname = this.$route.path.split('/')[1]
      let challenge = {"webname": this.webname};

      await Winery_from_Webname.getToken(challenge)
      .then(response => {
        if (response === 0) {
          if (this.debug) console.log("PageNotFound in mounted: winery with webname ", this.webname, " has no id and does NOT exists in DB");
          this.wineryExists = false;
        } else {
          if (this.debug) console.log("PageNotFound in mounted: winery with webname ", this.webname, " has id = ", response, " and exists in DB");
          this.wineryExists = true;
          this.winery_id = response;
        }
      })
      .catch(error => {
        this.wineryExists = true;
        if (this.debug) console.log("PageNotFound in mounted: await Winery_from_Webname.getToken - ", error);
      })

      if (this.wineryExists) { 
        // alert("BINGO "+ this.winery_id)
        // this.$router.push({path:'/Winery/Public', query: {winery_id:this.winery_id }} );
     
        await Wineries.getone(this.winery_id)
        .then(response=>{
          this.winery = response;
          delete this.winery._id;
          if (this.debug) console.log("PageNotFound in mounted: Winery "+ this.winery.name + " succesfully fetched from DB");
          document.title = this.winery.name
        })
        .catch(error=>{
          if (this.debug) console.log("PageNotFound in mounted: catch await Wineries.getone ERROR : ", error);
        })

        if (typeof this.winery.wine_ids === "undefined" || this.winery.wine_ids === null) {
          this.winery.wine_ids = [];
        }
        for (var i=0; i < this.winery.wine_ids.length; i ++) {
          if (typeof this.winery.wine_ids[i] != "undefined" && this.winery.wine_ids[i] != null) {
            await Wines.getone(this.winery.wine_ids[i])
            .then(response=> {
              this.wines.push(response)
              if (this.debug) console.log('PageNotFound in mounted : Wine with id= ', this.wines[i], ' succesfully fetched from DB')
            })
            .catch(error=>{
              if (this.debug) console.log('PageNotFound in mounted : catch await Wines.getone ERROR : ' + error);
            })
          } else {
            if (this.debug) console.log('PageNotFound in mounted : ERROR wine_ids[',i,'] = ', this.winery.wine_ids[i]);
          }
        }
     
     }

      this.isLoading = false
    },
    methods: {
      gotoStart() {
        this.$router.push({path:'/'});
      },
      gotoWebpage() {
        if (this.winery.webpage.substr(0,4) != "http") {
          this.winery.webpage = "http://" + this.winery.webpage
        }
        window.open(this.winery.webpage, '_blank');
      },
      gotoFacebook() {
        if (this.winery.facebook.substr(0,4) != "http") {
          this.winery.facebook = "http://" + this.winery.facebook
        }
        window.open(this.winery.facebook, '_blank');
      },
      gotoInstagram() {
        if (this.winery.instagram.substr(0,4) != "http") {
          this.winery.instagram = "http://" + this.winery.instagram
        }
        window.open(this.winery.instagram, '_blank');
      },
      gotoTwitter() {
        if (this.winery.twitter.substr(0,1) == "@") {
          this.winery.twitter = "https://twitter.com/" + makeWebname(this.winery.twitter)
        }
        window.open(this.winery.twitter, '_blank');
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}

.over {
    position: relative;
    width:90%;
    top: -30px;
    left: 45%;
    z-index: 3;
    color: black;
    padding: 3% 15% 10% 10%;
    background-color:white;
    border-radius: 1%;
      -webkit-transform: translate3d(-50%,0,0);
         -moz-transform: translate3d(-50%,0,0);
          -ms-transform: translate3d(-50%,0,0);
           -o-transform: translate3d(-50%,0,0);
              transform: translate3d(-50%,0,0);
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
       -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
}

.main-title {
  font-family: 'Segoe UI';
  font-size: 44px;
  font-weight: bolder;
  color: rgb(112,112,112);
}

.main-text {
  font-family: 'Segoe UI';
  font-size: 16px;
  color: rgb(112,112,112);
}

.w-picture{
  height: 200px;
  width: auto;
}
.w-detail {

}
.w-title {
  font-size: 18px;
  margin: 10px 0 0 0;
}

.w-subtitle {
  font-size:14px;
  margin:0.5vm;
}

.w-text {
  margin:0;
  font-size: 12px;
}

.w-smalltext {
  padding-top : 10px;
  font-size: 10px;
}
</style> 