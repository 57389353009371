export default function makeWebname(str) {
    str.replace(/[^a-zA-Z ]/g, "")

    let webname = str.toLowerCase();

    webname = webname.replace(new RegExp(" ", 'g'),"_");
    webname = webname.replace(new RegExp("\\s", 'g'),"");
    webname = webname.replace(new RegExp("[àáâãäå]", 'g'),"a");
    webname = webname.replace(new RegExp("æ", 'g'),"ae");
    webname = webname.replace(new RegExp("ç", 'g'),"c");
    webname = webname.replace(new RegExp("[èéêë]", 'g'),"e");
    webname = webname.replace(new RegExp("[ìíîï]", 'g'),"i");
    webname = webname.replace(new RegExp("ñ", 'g'),"n");                            
    webname = webname.replace(new RegExp("[òóôõö]", 'g'),"o");
    webname = webname.replace(new RegExp("œ", 'g'),"oe");
    webname = webname.replace(new RegExp("[ùúûü]", 'g'),"u");
    webname = webname.replace(new RegExp("[ýÿ]", 'g'),"y");
    webname = webname.replace(new RegExp("\\W", 'g'),"");

    return webname;
}