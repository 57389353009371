// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/userid_from_email/';
// const url = WEBSERVER + ':' + PORT + '/api/userid_from_email/';

const url = API + '/userid_from_email/';
const debug = false;

class UserID_from_Email {
    static getToken(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('UserID_from_Email.getToken: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('UserID_from_Email.getToken: ',error)
        })
    }
}

export default UserID_from_Email