<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineryPreviewMobile  v-if="matches" />
    <WineryPreviewDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineryPreviewMobile from "./WineryPreview_Mobile.vue";
  import WineryPreviewDesktop from "./WineryPreview_Desktop.vue";
 
  export default {
    name: 'WineryPreview',
    components: {
      MatchMedia,
      WineryPreviewMobile,
      WineryPreviewDesktop,
    },
  }
</script> 