import { render, staticRenderFns } from "./BeforeRegister.vue?vue&type=template&id=57804dcd&scoped=true&"
import script from "./BeforeRegister.vue?vue&type=script&lang=js&"
export * from "./BeforeRegister.vue?vue&type=script&lang=js&"
import style0 from "./BeforeRegister.vue?vue&type=style&index=0&id=57804dcd&prod&lang=css&"
import style1 from "./BeforeRegister.vue?vue&type=style&index=1&id=57804dcd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57804dcd",
  null
  
)

export default component.exports