<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <div>
        <br>
        <b-row style="height:40vh;margin:0" class="welcome-picture">
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" style="height:100%">
            <div class="welcome-overlay">
              <h1 class="main-title"><span style="color: rgb(195,147,130)">WYNE</span>MAKER</h1>
              <p class="main-subtitle">ERROR</p>
            </div> 
          </b-col>
        </b-row> 
        <b-row>
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8"> 
            <p class="w-title"> {{this.$route.query.error}}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1" sm="2">
          </b-col>
          <b-col cols="10" sm="8" class="text-right"> 
            <b-button v-on:click="gotoStart()" class="w-button-green">Start</b-button>
          </b-col>
        </b-row>
      </div>  
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'Error',
    data() {
      return {
        debug: false,

        isLoading : true,
      }
    }, 
    async mounted() {
      this.isLoading = false
    },
    methods: {
      gotoStart() {
        this.$router.push({path:'/'});
      }
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}

.over {
    position: relative;
    width:90%;
    top: -50px;
    left: 45%;
    z-index: 3;
    color: black;
    padding: 3% 15% 10% 10%;
    background-color:white;
    border-radius: 1%;
      -webkit-transform: translate3d(-50%,0,0);
         -moz-transform: translate3d(-50%,0,0);
          -ms-transform: translate3d(-50%,0,0);
           -o-transform: translate3d(-50%,0,0);
              transform: translate3d(-50%,0,0);
}

.main-title {
  font-family: 'Segoe UI';
  font-size: 44px;
  font-weight: bolder;
  color: rgb(112,112,112);
}

.main-text {
  font-family: 'Segoe UI';
  font-size: 16px;
  color: rgb(112,112,112);
}

.w-picture{
  height: 200px;
  width: auto;
}
.w-detail {

}
.w-title {
  font-size: 18px;
  margin: 10px 0 0 0;
}

.w-subtitle {
  font-size:14px;
  margin:0.5vm;
}

.w-text {
  margin:0;
  font-size: 12px;
}

.w-smalltext {
  padding-top : 10px;
  font-size: 10px;
}
</style> 