export default function size64(b64_string) {
    // Size of a base64 string 
    // x = (n * (3/4)) - y
    //  x is the size of a file in bytes
    //  n is the length of the Base64 String
    //  y will be 2 if Base64 ends with '==' and 1 if Base64 ends with '='.
    var end2 = b64_string.substring(b64_string.length-2);
    var y = 0;
    if ( end2 === "==") {
        y = 2;
    } else {
        y = 1;
    }
    return b64_string.length*3/4 - y
}