import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
import BeforeRegister from '@/components/BeforeRegister'
import Register from '@/components/Register'
import RegistrationReceived from '@/components/RegistrationReceived'
import Profile from '@/components/Profile'
import Confirmation from '@/components/Confirmation'
import ChangeEmail from '@/components/ChangeEmail'

import AddWinery2Registration from '@/components/AddWinery2Registration'

import WineryWelcome from '@/components/WineryWelcome'

import WineryGeneral from '@/components/WineryGeneral'
import WineryContact from '@/components/WineryContact'
import WinerySocialMedia from '@/components/WinerySocialMedia'
import WineryPictures from '@/components/WineryPictures'
import WineryWines from '@/components/WineryWines'
import WineryPreview from '@/components/WineryPreview'

import WineEdit from '@/components/WineEdit'
import WineShow from '@/components/WineShow'

import Wine from '@/components/Wine'

import AboutUs from '@/components/AboutUs'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'

import PageNotFound from '@/components/PageNotFound'
import Error from '@/components/Error'

import Terms from '@/components/Terms'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/RegistrationReceived',
            name: 'RegistrationReceived',
            component: RegistrationReceived
        },
        {
            path: '/BeforeRegister',
            name: 'BeforeRegister',
            component: BeforeRegister
        },
        {
            path: '/Register',
            name: 'Register',
            component: Register
        },
        {
            path: '/Profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/Confirmation',
            name: 'Confirmation',
            component: Confirmation
        },
        {
            path: '/ChangeEmail',
            name: 'ChangeEmail',
            component: ChangeEmail
        },
        {
            path: '/AddWinery2Registration',
            name: 'AddWinery2Registration',
            component: AddWinery2Registration
        },
        {
            path: '/Winery/Welcome',
            name: 'WineryWelcome',
            component:WineryWelcome
        },
        {
            path: '/Winery/General',
            name: 'WineryGeneral',
            component:WineryGeneral
        },
        {
            path: '/Winery/Contact',
            name: 'WineryContact',
            component:WineryContact
        },
        {
            path: '/Winery/SocialMedia',
            name: 'WinerySocialMedia',
            component:WinerySocialMedia
        },
        {
            path: '/Winery/Pictures',
            name: 'WineryPictures',
            component:WineryPictures
        },
        {
            path: '/Winery/Wines',
            name: 'WineryWines',
            component:WineryWines
        },
        {
            path: '/Winery/Preview',
            name: 'WineryPreview',
            component:WineryPreview
        },   
        {
            path: '/Winery/WineEdit',
            name: 'WineEdit',
            component: WineEdit
        },
        {
            path: '/Winery/WineShow',
            name: 'WineShow',
            component: WineShow
        }, 

        {
            path: '/Wine',
            name: 'Wine',
            component: Wine
        },
        {   
            path: '/Error', 
            name: 'Error',
            component: Error 
        },
        {
            path: '/AboutUs',
            name: 'AboutUs',
            component:AboutUs
        },     
        {   
            path: '/ForgotPassword', 
            name: 'ForgotPassword',
            component: ForgotPassword 
        },
        {   
            path: '/ResetPassword', 
            name: 'ResetPassword',
            component: ResetPassword 
        },
        {
            path: '/Terms',
            name: 'Terms',
            component: Terms
        }, 
        {   
            path: '*', 
            name: 'PageNotFound',
            component: PageNotFound 
        },
        // { path: '/:pathMaTermsh(.*)*', component: PageNotFound }, // as of vue 3
    ],
    mode: 'history'
})