<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession" >
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <div style="height:15vh">
            </div>
            <div class="w-box" style="width:60%; height:580px">
              <p class="w-subtitle" style="text-align:right">add wine</p>
              <b-row style="margin-left:5px;margin-right:5px">
                <b-col class="text-left">
                  <b-img v-if="leftVisible"
                    class="w-cursor-click"
                    width=30px
                    height=30px
                    :src="require('@/assets/img/left.png')"
                    @click="onLeft"
                  >
                  </b-img>
                </b-col>                
                <b-col cols="8" class="text-center" style="padding-left:0;padding-right:0;">
                  <p v-if="this.pageCount == 0" 
                    class="current-page w-cursor-default"
                  >
                    <b>wine</b> -
                  </p>
                  <p v-else 
                    class="w-page w-cursor-click"
                    @click="onWine"
                  >
                    wine -
                  </p>

                  <p v-if="this.pageCount == 1" 
                    class="current-page w-cursor-default"
                  >
                    <b>detail</b> -
                  </p>
                  <p v-else 
                    class="w-page w-cursor-click"
                    @click="onDetail"
                  >
                    detail -
                  </p>

                  <p v-if="this.pageCount == 2" 
                    class="current-page w-cursor-click"
                  >
                    <b>pictures</b> -
                  </p>
                  <p v-else 
                    class="w-page w-cursor-click"
                    @click="onPictures"
                  >
                    pictures -
                  </p>

                  <p v-if="this.pageCount == 3" 
                    class="current-page w-cursor-click"
                  >
                    <b>impressions</b>
                  </p>
                  <p v-else 
                    class="w-page w-cursor-click"
                    @click="onImpressions"
                  >
                    impressions
                  </p>

                  <p class="w-smalltext" style="text-align:center">page {{this.pageCount+1}}/{{this.maxPage+1}}</p>
                </b-col>
                <b-col class="text-right">
                  <b-img v-if="rightVisible"
                    class="w-cursor-click"
                    width=30px
                    height=30px
                    :src="require('@/assets/img/right.png')"
                    @click="onRight"
                  >
                  </b-img>
                </b-col>
                <br>                
              </b-row>   
              <b-row style="margin-left:15px;margin-right:15px">
                <div v-if="pageCount == 0" class="w-page">
                  <b-form autocomplete="off">
                      <b-form-group style="text-align:left">
                        <label class="w-label w-required" for="input-name">name</label>
                        <b-form-input
                          id="input-name"
                          class="w-input"
                          v-model="form.name"
                          v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                        {{nameFeedback}}
                        </b-form-invalid-feedback>
                      </b-form-group>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="input-type">type</label>
                        <b-form-input
                          id="input-type"
                          class="w-input"
                          v-model="form.type"
                          aria-describedby="input-live-help input-live-feedback"
                        ></b-form-input>
                      </b-form-group>
                      <b-row>
                        <b-col>
                          <b-form-group style="text-align:left">
                            <label class="w-label w-required" for="countryList">country</label>        
                            <model-select
                              class="w-select"
                              :options="countries"
                              v-model="form.country_id"
                              v-bind:class="{'form-control':true, 'is-invalid' : !this.countryValid}"
                              @input="inputCountry"
                            >
                            </model-select>
                            <b-form-invalid-feedback id="input-live-feedback">
                            {{countryFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group style="text-align:left">
                            <label class="w-label" for="regionList" v-if="form.country=='Italia'">regione</label>
                            <label class="w-label" for="regionList" v-else-if="form.country=='España'">región</label>
                            <label class="w-label" for="regionList" v-else-if="form.country=='Deutschland'">anbaugebiet</label>
                            <label class="w-label" for="regionList" v-else-if="form.country=='South-Africa'">district</label>
                            <label class="w-label" for="regionList" v-else>region</label>        
                            <model-select
                              class="w-select"
                              :options="regions"
                              :isDisabled= !region_isActive
                              v-model="form.region_id"
                              @input="inputRegion"
                            >
                            </model-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="appellationList" v-if="form.country=='Italia'">denominazione</label>
                        <label class="w-label" for="appellationList" v-else-if="form.country=='España'">denominación</label>
                        <label class="w-label" for="appellationList" v-else-if="form.country=='Deutschland'">einzellage	- großlage</label>
                        <label class="w-label" for="appellationList" v-else-if="form.country=='USA'">AVA</label>
                        <label class="w-label" for="appellationList" v-else-if="form.country=='South-Africa'">ward</label>
                        <label class="w-label" for="appellationList" v-else>appellation</label>        
                        <model-select
                          class="w-select"
                          :options="appellations"
                          :isDisabled = !appellation_isActive
                          v-model="form.appellation_id"
                          @input="inputAppellation"
                        >
                        </model-select>
                      </b-form-group>
                      <b-form-group style="text-align:left">
                        <label class="w-label" for="regionList">classification</label>        
                        <model-select
                          class="w-select"
                          :options="classifications"
                          :isDisabled = !classification_isActive
                          v-model="form.classification_id"
                          @input="inputClassification"
                        >
                        </model-select>
                      </b-form-group>
                      <!-- <b-row>
                        <b-col cols="4">
                          <b-form-group style="text-align:left">
                            <label class="w-label w-required" for="colorList">color</label>        
                            <model-select
                              class="w-select"
                              :options="colors"
                              v-model="color_id"
                              v-bind:class="{'form-control':true, 'is-invalid' : !this.colorValid}"
                              @input="inputColor"
                            >
                            </model-select>
                            <b-form-invalid-feedback id="input-live-feedback">
                            {{colorFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col cols="4">
                          <b-form-group style="text-align:center">
                            <label class="w-label" for="sparklingToggle">sparkling</label>        
                            <b-form-checkbox 
                              id="sparklingToggle"
                              v-model="form.sparkling"
                            >
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                        <b-col cols="4">
                          <b-form-group style="text-align:center">
                            <label class="w-label" for="sweetToggle">sweet</label>        
                            <b-form-checkbox 
                              id="sweetToggle"
                              v-model="form.sweet"
                            >
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-row> -->
                      <b-row>
                        <b-col>
                          <b-form-group style="text-align:left">
                            <label class="w-label w-required" for="colorList">color</label>        
                            <model-select
                              class="w-select"
                              :options="colors"
                              v-model="color_id"
                              v-bind:class="{'form-control':true, 'is-invalid' : !this.colorValid}"
                              @input="inputColor"
                            >
                            </model-select>
                            <b-form-invalid-feedback id="input-live-feedback">
                            {{colorFeedback}}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group style="text-align:left;display: flex;position:relative;top:15px">   
                            <b-form-checkbox 
                              id="sparklingToggle"
                              v-model="form.sparkling"
                              style="flex: 1;top:10px"
                            >
                            <label class="w-label" for="sparklingToggle">sparkling</label>
                            </b-form-checkbox>
                            <b-form-checkbox 
                              id="sweetToggle"
                              v-model="form.sweet"
                              style="flex: 2"
                            >
                            <label class="w-label" for="sweetToggle">sweet</label>
                            </b-form-checkbox>
                          </b-form-group>
                        </b-col>
                      </b-row>
                  </b-form>
                </div>
                <div v-if="pageCount == 1" class="w-page">
                  <b-form autocomplete="off">
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-grapes">grapes - cépage - cepa - vitigno</label>
                      <b-form-input
                        id="input-grapes"
                        class="w-input"
                        v-model="form.grapes"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-longevity">longevity - longévité - longevidad - longevità</label>
                      <b-form-input
                        id="input-longevity"
                        class="w-input"
                        v-model="form.longevity"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-vines">vines - vignes - enredaderas - viti</label>
                      <b-form-input
                        id="input-vines"
                        class="w-input"
                        v-model="form.vines"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-yields">yields - rendements - rendimientos - rendimenti</label>
                      <b-form-input
                        id="input-yields"
                        class="w-input"
                        v-model="form.yields"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-vinification">vinification - vinificación - vinificazione</label>
                      <b-form-input
                        id="input-vinification"
                        class="w-input"
                        v-model="form.vinification"
                      ></b-form-input>
                    </b-form-group>
                  </b-form>
                </div>
                <div v-if="pageCount == 2" class="w-page">
                  <div style="height:350px;width:100%">
                    <b-row>
                      <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">                
                        <div v-if="form.image_files[0]" class="bottle-box"> 
                          <b-img  
                            v-bind:src="form.image_files[0].dataURL"
                          >
                          </b-img>
                          <div v-on:click.stop="onDeletePicture(0)" class="delete-picture">
                            <b-img
                              :src="require('@/assets/img/delete-picture.png')"
                              style="width:100%;height:100%;border-radius:50%;border-style:none"
                            ></b-img>
                          </div>
                        </div>
                        <div v-else class="bottle-box">
                          <p style="margin-top: 15px;font-size: 96px;font-family: ui-monospace; color:grey">+</p>
                        </div>
                      </b-col>
                      <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">               
                        <div v-if="form.image_files[1]" class="bottle-box"> 
                          <b-img  
                            v-bind:src="form.image_files[1].dataURL"
                          >
                          </b-img>
                          <div v-on:click.stop="onDeletePicture(1)" class="delete-picture">
                            <img
                              :src="require('@/assets/img/delete-picture.png')"
                              style="height:100%;border-radius:50%;border-style:none"
                            />
                          </div>
                        </div>
                        <div v-else class="bottle-box">
                          <p style="margin-top: 15px;font-size: 96px;font-family: ui-monospace; color:grey">+</p>
                        </div>
                      </b-col>
                      <b-col class="justify-content-center" style="height:100%; width:33%; display:flex">             
                        <div v-if="form.image_files[2]" class="bottle-box"> 
                          <b-img  
                            v-bind:src="form.image_files[2].dataURL"
                            style="height:100%"
                          >
                          </b-img>
                          <div v-on:click.stop="onDeletePicture(2)" class="delete-picture">
                            <img
                              :src="require('@/assets/img/delete-picture.png')"
                              style="width:100%;height:100%;border-radius:50%;border-style:none"
                            />
                          </div>
                        </div>
                        <div v-else class="bottle-box">
                          <p style="margin-top: 15px;font-size: 96px;font-family: ui-monospace; color:grey">+</p>
                        </div>
                      </b-col>
                    </b-row>
                    <vue-dropzone 
                      id="imgDropzone" ref="imgDropzone" class="winedropzone" 
                      :options="dropzoneOptions"
                      @vdropzone-thumbnail="vfileAdded" 
                    >
                    </vue-dropzone>  
                  </div>
                  <div style="height:50px">
                  </div>
                </div>
                <div v-if="pageCount == 3" class="w-page">
                  <b-form autocomplete="off">
                    <b-form-group style="text-align:left">
                      <label class="w-label" for="input-tastingnotes">tasting notes</label>
                      <b-form-textarea
                        id="input-tastingnotes"
                        class="w-textarea-big"
                        style="padding-left:15px; padding-right:15px"
                        v-model="form.tasting_notes"
                        rows="3"
                        max-rows="10"
                      ></b-form-textarea>
                    </b-form-group>                    
                  </b-form>
                </div>               
              </b-row>  
            </div>
            <div style="width:60%">
              <b-row>
                <b-col  class="text-left">
                  <b-button v-on:click="onBack()" class="w-button-pink">back</b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button v-on:click="onSave()" class="w-button-green">save</b-button>
                </b-col>
              </b-row>
            </div>
            <br>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
    <b-modal id="delete" title="Delete Wine" @ok="deleteWine">
      <p class="w-title ">{{form.name}}</p>
      <p class="w-subtitle" v-if="form.type">{{form.type}}</p>
      <p class="w-subtitle">{{form.color}}</p>
      <p class="w-subtitle" v-if="form.appellation">{{form.appellation}}</p>       
      <hr>
      <p class="w-title text-center"> ARE YOU SHURE YOU WANT TO DELETE THIS?</p>
    </b-modal>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';
  import size64 from '../assets/js/size64.js';
  import mimeType from '../assets/js/mimeType.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import { ModelSelect } from 'vue-search-select';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  import Geographies from '../Geographies.js'
  import Classifications from '../Classifications.js'

  import Wineries from '../Wineries.js'
  import Wines from '../Wines.js'

  export default {
    name: 'AddWine',
    components:{
      NoAccess,
      Menu,
      ModelSelect,
      vueDropzone: vue2Dropzone
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      }
    },
    data() {
      return {
        debug: false,
        isLoading: true,
        
        form: {
          name:'',
          type:'',
          country_id:0,
          country:'',
          region_id:0,
          region:'',
          appellation_id:0,
          appellation:'',
          classification_id:0,
          classification:'',
          sparkling:false,
          sweet:false,
          color:'',
          grapes:'',
          longevity:'',
          vines:'',
          yields:'',
          vinification:'',
          image_files:[],
          winery_id:'',
          tasting_notes:'',
        },
        wine_id:'',

        winery:{},
        winery_id:0,

        color_id:'',
        
        nameValid : true,
        nameFeedback : '',
        countryValid : true,
        countryFeedback : '',
        colorValid : true,
        colorFeedback : '',
        isValid : false,
        
        exists : false,

        countries: [],
        regions: [],
        appellations:[],

        geographies:[],
        current_geography:{},

        classifications:[],

        colors:[{text:'Red',value:0},{text:'White',value:1},{text:'Rosé',value:2}],

        region_isActive: false,
        appellation_isActive: false,
        classification_isActive: false,

        result:[],

        // dropzone data
        dropzoneOptions: {
          url: 'https://httpbin.org/post',
          previewTemplate: this.template(),
          acceptedFiles:'.jpg,.jpeg,.png',
          dictDefaultMessage : '',
          maxFilesize: 10,
        },
        dropzoneFilled: false,

        // paging
        pageCount:0,
        maxPage:3,
        leftVisible:false,
        rightVisible:true,

      }
    },
    async mounted() {
      if (this.debug) console.log("")
      if (this.debug) console.log("WineEdit.mounted: START")
      
      // Read the URL
      if (typeof this.$route.query.winery_id !== 'undefined') {
        this.winery_id = this.$route.query.winery_id;
        if (this.debug) console.log( "WineEdit.mounted: winery_id ", this.winery_id ," found in URL.");
        await Wineries.getone(this.winery_id)
        .then(response=> {
          this.winery = response;
          this.winery_id = response._id
          delete this.winery._id
          if (this.debug) console.log( "WineEdit.mounted: winery ", this.winery , " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("WineEdit.mounted: await Wineries.getone ", error);
        })
      } else {
        if (this.debug) console.log( "WineEdit.mounted: winery_id NOT found in URL.");
        this.winery_id = 0;
        alert("WineEdit.mounted: winery_id NOT found in URL = FATAL ERROR")
        return;
      }

      if (typeof this.$route.query.wine_id !== 'undefined') {
        this.wine_id = this.$route.query.wine_id;
        if (this.debug) console.log( "WineEdit.mounted: wine_id ", this.wine_id ," found in URL.");
        await Wines.getone(this.wine_id)
        .then(response=> {
          this.form = response;
          delete this.form._id
          if (this.debug) console.log("WineEdit.mounted: wine ", this.form , " succesfully fetched from DB");
        })
        .catch(error=> {
          if (this.debug) console.log("WineEdit.mounted: await Wines.getone ", error);
        })
      } else {
        if (this.debug) console.log( "WineEdit.mounted: wine_id NOT found in URL - MUST BE NEW");
        this.wine_id = 0;
      }

      await Geographies.get()
      .then(response=>{
        for (let i = 0 ; i < response.length ; i++) {
          this.geographies.push(response[i])
        }
        if (this.debug) console.log("WineEdit.mounted: Geographies ", response, " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log("WineEdit.mounted: catch await Geographies.get() - error : ", error);
      })
      
      // this.region_isActive = false;
      // this.appellation_isActive = false;
      // this.classification_isActive = false;      
          
      if (this.wine_id != 0) {
        //WINE EXISTS
        if (this.form.appellation_id !=0) {
          await Geographies.getone(this.form.appellation_id)
          .then(response => {
            this.current_geography = response
            if (this.debug) console.log("WineEdit.mounted: appellation current_geography is ", response)
          })
          .catch(error =>{
            if (this.debug) console.log("WineEdit.mounted: Geography.get failed with ERROR ", error)
          })
        } else  if (this.form.region_id !=0) {
          await Geographies.getone(this.form.region_id)
          .then(response => {
            this.current_geography = response
            if (this.debug) console.log("WineEdit.mounted: region current_geography is ", response)
          })
          .catch(error =>{
            if (this.debug) console.log("WineEdit.mounted: Geography.get failed with ERROR ", error)
          })
        } else  if (this.form.country_id !=0) {
          await Geographies.getone(this.form.country_id)
          .then(response => {
            this.current_geography = response
            if (this.debug) console.log("WineEdit.mounted: country  current_geography is ", response)
          })
          .catch(error =>{
            if (this.debug) console.log("WineEdit.mounted: Geography.get failed with ERROR ", error)
          })
        } else {
          alert("BIG PROBLEM: NO current_geography")
        }

        // Country of existing wine
        for (let i = 0 ; i < this.geographies.length ; i++) {
          if (this.geographies[i].level == 1) {
            this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        if (this.countries.length > 0) {
          this.countries.sort((a,b)=>(a.text>b.text)?1:-1);
        }   

        // Region of existing wine
        this.regions = [];
        for ( let i = 0 ; i < this.geographies.length ; i++ ) {
          if (this.geographies[i].level == 2) {
            if ( this.geographies[i].country_id == this.form.country_id ) {
              if (this.debug) console.log("FullDefine in mounted: regions of current country - ", this.geographies[i].name)
              this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }
        }
        if (this.regions.length > 0) {
          this.region_isActive = true;
          this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
        } 

        // Appellation of existing wine
        this.appellations = [];
        for ( let i = 0 ; i < this.geographies.length ; i++ ) {
          if (this.geographies[i].level == 3) {
            if ( this.geographies[i].region_id == this.form.region_id ) {
              this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }
        }     
        if (this.appellations.length > 0) {
          this.appellation_isActive = true;
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
        }  

        // Classification of existing wine
        this.classifications = [];
        if (this.current_geography.classification_ids.length > 0) {
          for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i++ ) {
            await Classifications.getone(this.current_geography.classification_ids[i])
            .then(response => {
              if (typeof response != "undefined") {
                if (this.debug) console.log("WineEdit.mounted: classification exists with response ", response)
                this.classifications.push({value:response._id,text:response.name})
              } else {
                if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
              }
            })
            .catch(error => {
              if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
            })
          }
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
          if ( this.classifications.length == 1) {
            this.form.classification_id = this.classifications[0].value
          }
        } else {
          this.classification_isActive = false
        }  

        for (let i = 0 ; i < this.colors.length ; i ++) {
          if ( this.colors[i].text == this.form.color) {
            this.color_id = i
          }
        }
      } else {
        for (let i = 0 ; i < this.geographies.length ; i++) {
          if (this.geographies[i].level == 1) {
            this.countries.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
          if (this.geographies[i].level == 3) {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        this.region_isActive = false;
        this.appellation_isActive = false;
        this.classification_isActive = false;      
      }

      this.isLoading = false;
      if (this.debug) console.log("WineEdit.mounted: STOP")
    },
    updated(){
      if (!this.dropzoneFilled) {
        try {
        // Populate the DropZone Manually
          if (typeof this.form.image_files !== 'undefined') {
            for (var i=0; i < this.form.image_files.length;i++) {
              var dataURL = this.form.image_files[i].dataURL
              var filename = this.form.image_files[i].upload.filename
              var extension = filename.substring(filename.indexOf(".")+1)
              var mime_type = mimeType(extension);
              var size = size64(dataURL)

              var file = { name: filename, size: size, type: mime_type, dataURL: dataURL};
              this.$refs.imgDropzone.manuallyAddFile(file, dataURL);
              this.$refs.imgDropzone.dropzone.emit('thumbnail', file, file.dataURL)
              this.$refs.imgDropzone.dropzone.emit('complete', file)
            }
          }
          this.dropzoneFilled = true;
        } catch(err) {
          if (this.debug) console.log(err.message)
        }
      }
    },
    methods: {
      validate(){
          if( this.form.name.length >= 3) {
              this.nameValid = true;
              this.nameFeedback = '';
          } else {
              this.nameValid = false;
              this.nameFeedback = "Name must be minimal 3 characters"
          }
          if ( this.form.country != '') {
            this.countryValid = true;
            this.countryFeedback = ''
          } else {
            this.countryValid = false;
            this.countryFeedback = 'Please select valid country.'
          }
          if ( this.form.color != '') {
            this.colorValid = true;
            this.colorFeedback = ''
          } else {
            this.colorValid = false;
            this.colorFeedback = 'Please select valid color.'
          }
          this.isValid = this.nameValid && this.countryValid && this.colorValid;
      },
      
      async onSave() {
        if (this.debug) console.log("");
        if (this.debug) console.log("WineEdit.onSave: START");
        this.validate()
        this.form.winery_id = this.winery_id
        if(this.isValid){
          //THIS IS WHERE YOU SUBMIT DATA TO SERVER
          if (this.wine_id != 0) {
            if (this.debug) console.log("WineEdit.onSave: Wine exists, and has id=", this.wine_id)
            await Wines.update(this.wine_id,this.form)
            .then(response => {
              if (this.debug) console.log("WineEdit.onSave: wine ",this.form," successfully updated with response: ", response)
            })
            .catch(error => {
              if (this.debug) console.log("WineEdit.onSave: catch Wines.update with error: ", error)
            }) 
          } else {
            if (this.debug) console.log("WineEdit.onSave: Wine does NOT exists - we create it")
            await Wines.create(this.form)
            .then(response => {
              this.wine_id = response
              if (this.debug) console.log("WineEdit.onSave: wine ",this.form," successfully created with response: ", response)
            })
            .catch(error => {
              if (this.debug) console.log("WineEdit.onSave: catch Wines.create with error: ", error)
            }) 
          
            if (this.debug) console.log("WineEdit.onSave: Adding wine to wine_ids from winery ", this.winery.name)       
            this.winery.wine_ids.push(this.wine_id);
            await Wineries.update(this.winery_id,this.winery)
            .then(response => {
              if (this.debug) console.log("WineEdit.onSave: winery ",this.winery," successfully updated with response: ", response)
            })
            .catch(error => {
              if (this.debug) console.log("WineEdit.onSave: catch Wineries.update with error: ", error)
            })  
          }

          await this.$forceUpdate();
          this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id }});
        } else  {
          this.pageCount = 0
          this.leftVisible = false;
          this.rightVisible = true;
          if (this.debug) console.log("WineEdit.onSave: Returning without Save because validation form failed.")
          return;
        }
      },
      onBack() {
        this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id}});
      },
        
      onDelete() {
        if (this.debug) console.log("Delete pressed");
      },

      async deleteWine(bvModalEvt) {
        let this_winery = {};
        let this_winery_id = 0;
        await Wineries.getone(this.form.winery_id)
        .then(response=> {
          if (this.debug) console.log("WineEdit.deleteWine: Winery succesfully retrieved ", response);
          this_winery = response;
          this_winery_id = this_winery._id;
          delete this_winery._id;
        })
        .catch(error=> {
          if (this.debug) console.log("WineEdit.deleteWine: catch await Wineries.get ERROR :", error);
        })
        for( var i = 0; i < this_winery.wine_ids.length; i++){
          if ( this_winery.wine_ids[i] === this.wine_id) { 
            this_winery.wine_ids.splice(i, 1);
          }
        }
        await Wineries.update(this_winery_id,this_winery)
        .then(response => {
          if (this.debug) console.log("WineEdit.deleteWine: Winery succesfully updated ", response);
        })
        .catch(error => {
          if (this.debug) console.log("WineEdit.deleteWine: catch await Wineries.update ERROR :", error);
        }) 

        await Wines.delete(this.wine_id)
        .then(response =>{
          if (this.debug) console.log("WineEdit.deleteWine: Wine succesfully deleted ", response);
        })
        .catch(error =>{
          if (this.debug) console.log("WineEdit.deleteWine: catch await Wines.delete ERROR :", error);
        })
        this.$router.push({path:'/Winery/Wines', query: {winery_id:this.$route.query.winery_id}});
        
      },

      async inputCountry(value) {
        if (this.debug) console.log("");        
        if (this.debug) console.log("WineEdit.inputCountry: START");

        this.form.country_id = value;
        for (let i=0; i< this.countries.length; i++) {
          if (this.countries[i].value === value) {
            this.form.country = this.countries[i].text;
          }
        }

        if (this.debug) console.log("WineEdit.inputCountry: country_id = ",this.form.country_id);
        if (this.debug) console.log("WineEdit.inputCountry: country = ",this.form.country);

        this.regions =[];
        this.form.region_id='';

        this.appellations =[];
        this.form.appellation_id='';

        // Adapt regions
        this.regions = [];
        this.appellations =[];
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 2 && this.geographies[i].country_id == value)  {
            this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
          if (this.geographies[i].level == 3 && this.geographies[i].country_id == value)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }

        if (this.debug) console.log( "WineEdit.inputCountry: Regions from Country ", this.form.country," ", this.regions, " succesfully found");
        if (this.debug) console.log( "WineEdit.inputCountry: Appellations from Country ", this.form.country," ", this.appellations, " succesfully found");
      
        if (this.regions.length > 0 ) {
          this.regions.sort((a,b)=>(a.text>b.text)?1:-1);
          this.region_isActive = true
        } else {
          this.region_isActive = false
        }

        if (this.appellations.length > 0) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true;
        } else {
          this.appellation_isActive = false;
        }

        this.classifications =[];
        this.form.classification_id=0;
        this.form.classification='';

        // Adapt classifications
        this.classifications = []
        for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
          await Classifications.getone(this.current_geography.classification_ids[i])
          .then(response => {
            if (typeof response != "undefined") {
              if (this.debug) console.log("WineEdit.inputCountry: classification exists with response ", response)
              this.classifications.push({value:response._id,text:response.name})
            } else {
              if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
            }

          })
          .catch(error => {
            if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
          })
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
        } else {
          this.classification_isActive = false
        }        
        
        if (this.debug) console.log("WineEdit.inputCountry: STOP");
      },

      async inputRegion(value) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WineEdit.inputRegion: START")

        this.form.region_id = value;
        for (let i=0; i< this.regions.length; i++) {
          if (this.regions[i].value === value) {
            this.form.region = this.regions[i].text;
          }
        }

        if (this.debug) console.log("WineEdit.inputRegion: region_id = ",this.form.region_id)
        if (this.debug) console.log("WineEdit.inputRegion: region = ",this.form.region)

        this.appellations = [];
        this.form.appellation_id = '';

        if(typeof this.form.region_id !== 'undefined') {  
          // Adapt regions
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i]._id == value) {
              this.current_geography = this.geographies[i];
            }
            if (this.geographies[i].level == 3 && this.geographies[i].region_id == value)  {
              this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }

          if (this.debug) console.log( "WineEdit.inputRegion: Appellations from Region ", this.form.region," ", this.appellations, " succesfully found");
      
          if (this.appellations.length > 0 ) {
            this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
            this.appellation_isActive = true
          } else {
            this.appellation_isActive = false
          }

          this.classifications = [];
          this.form.classification_id = 0;
          this.form.classification = '';

          // Add classifications
          this.classifications = []
          for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
            await Classifications.getone(this.current_geography.classification_ids[i])
            .then(response => {
              if (typeof response != "undefined") {
                if (this.debug) console.log("WineEdit.inputCountry: classification exists with response ", response)
                this.classifications.push({value:response._id,text:response.name})
              } else {
                if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
              }

            })
            .catch(error => {
              if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
            })
          }
          this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
              
          if (this.classifications.length > 0) {
            this.classification_isActive = true
          } else {
            this.classification_isActive = false
          }          

          if (this.debug) console.log("WineEdit.inputRegion: STOP")
        }  
      },

      async inputAppellation(value) {
        if (this.debug) console.log("")
        if (this.debug) console.log("WineEdit.inputAppellation: START")

        this.form.appellation_id = value;
        // for (let i = 0; i < this.appellations.length; i++) {
        //   if (this.appellations[i].value == value ) {
        //     this.form.appellation = this.appellations[i].text
        //   }
        // }

        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
        }

        if (this.debug) console.log("WineEdit.inputAppellation: current_geography = ",this.current_geography)
        if (this.debug) console.log("WineEdit.inputAppellation: appellation_id = ",this.form.appellation_id)
        if (this.debug) console.log("WineEdit.inputAppellation: appellation = ",this.form.appellation)
        
        if (this.form.country_id == 0 || this.form.country_id == "") {
          this.form.country_id = this.current_geography.country_id
          for(let i = 0; i < this.geographies.length ; i++ ) {
            if (this.geographies[i].level == 2 && this.geographies[i].country_id == this.form.country_id)  {
              this.regions.push({value:this.geographies[i]._id,text:this.geographies[i].name})
            }
          }
          for (let i=0; i< this.countries.length; i++) {
            if (this.countries[i].value === this.form.country_id) {
              this.form.country = this.countries[i].text;
            }
          }
        }
        if (this.debug) console.log("WineEdit.inputAppellation: country_id = ",this.form.country_id)
        if (this.debug) console.log("WineEdit.inputAppellation: country = ",this.form.country)

        if (this.form.region_id == 0 || this.form.region_id == "") {
          this.form.region_id = this.current_geography.region_id
          for (let i=0; i< this.regions.length; i++) {
            if (this.regions[i].value === this.form.region_id) {
              this.form.region = this.regions[i].text;
            }
          }
          this.region_isActive = true;
        }
        if (this.debug) console.log("WineEdit.inputAppellation: region_id = ",this.form.region_id)
        if (this.debug) console.log("WineEdit.inputAppellation: region = ",this.form.region)


        // Add classificationss
        this.classifications = []
        this.form.classification_id = 0;
        this.form.classification = '';
        for ( let i = 0 ; i < this.current_geography.classification_ids.length ; i ++) {
          await Classifications.getone(this.current_geography.classification_ids[i])
          .then(response => {
            if (typeof response != "undefined") {
              if (this.debug) console.log("WineEdit.inputCountry: classification exists with response ", response)
              this.classifications.push({value:response._id,text:response.name})
            } else {
              if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with response ", response)
            }

          })
          .catch(error => {
            if (this.debug) console.log("WineEdit.inputCountry: classification ",this.current_geography.classification_ids[i],"DOES NOT exists with error ", error)
          })
        }
        this.classifications.sort((a,b)=>(a.order>b.order)?1:-1);
            
        if (this.classifications.length > 0) {
          this.classification_isActive = true
          if ( this.classifications.length == 1) {
            this.form.classification_id = this.classifications[0].value
            this.form.classification = this.classifications[0].text
          }
        } else {
          this.classification_isActive = false
        }
        
        // New 2022-08-03 Adapt Appellationlist accourding to selected region
        this.appellations = []
        for(let i = 0; i < this.geographies.length ; i++ ) {
          if (this.geographies[i]._id == value) {
            this.current_geography = this.geographies[i];
          }
          if (this.geographies[i].level == 3 && this.geographies[i].region_id == this.form.region_id)  {
            this.appellations.push({value:this.geographies[i]._id,text:this.geographies[i].name})
          }
        }
        if (this.appellations.length > 0 ) {
          this.appellations.sort((a,b)=>(a.text>b.text)?1:-1);
          this.appellation_isActive = true
        } else {
          this.appellation_isActive = false
        }
        for (let i = 0; i < this.appellations.length; i++) {
          if (this.appellations[i].value == value ) {
            this.form.appellation = this.appellations[i].text
          }
        }        

        if (this.debug) console.log("WineEdit.inputAppellation: classification_id = ",this.form.classification_id)
        if (this.debug) console.log("WineEdit.inputAppellation: classification = ",this.form.classification)

        if (this.debug) console.log("WineEdit.inputAppellation: STOP")
      },

      inputClassification(value) {
        this.form.classification_id = value;
        if (this.debug) console.log("WineEdit.inputClassification - classification_id = ",this.form.classification_id)
        for (let i = 0; i < this.classifications.length; i++) {
          if (this.classifications[i].value == value ) {
            this.form.classification = this.classifications[i].text
          }
        }
        if (this.debug) console.log("WineEdit.inputClassification - classification = ",this.form.classification)
      },

      inputColor(value) {
        if (this.debug) console.log("WineEdit.inputColor - value = ",value) 
        for (let i = 0; i < this.colors.length; i++) {
          if (this.colors[i].value == value ) {
            this.form.color = this.colors[i].text;
            this.color_id = i;
          }
        }
        if (this.debug) console.log("WineEdit.inputColor - color = ",this.form.color)
      },

      async vfileAdded(new_image_file) {
        if ( !new_image_file.manuallyAdded ) {
          if (this.debug) console.log("wine picture URL: ", new_image_file);
          let org_base64 = new_image_file.dataURL;
          let cc_base64 = await cc64(org_base64,1,3);
          new_image_file.dataURL = cc_base64;
          this.form.image_files.push(new_image_file);
          if (this.form.image_files.length > 3) {
            this.form.image_files.shift();
          }
          this.hasChanged = true;
          this.$refs.imgDropzone.removeFile(new_image_file);
          //this.onSave();
        }
      },

      onDeletePicture(i){
        this.form.image_files.splice(i, 1);
        this.hasChanged = true;
        //this.onSave();
      },

      template: function () {
         return `<div class="dz-preview">
                </div>
        `;
      },

      async onLeft() {
        this.pageCount--;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      async onRight() {
        this.pageCount++;
        if (this.pageCount > 0) {
          this.leftVisible = true;
        } else {
          this.leftVisible = false;
        }
        if (this.pageCount >= this.maxPage) {
          this.rightVisible = false;
        } else {
          this.rightVisible = true;
        }
      },
      async onWine() {
        this.pageCount = 0;
        this.leftVisible = false;
        this.rightVisible = true;
      }, 
      async onDetail() {
        this.pageCount = 1;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onPictures() {
        this.pageCount = 2;
        this.leftVisible = true;
        this.rightVisible = true;
      },
      async onImpressions() {
        this.pageCount = 3;
        this.leftVisible = true;
        this.rightVisible = false;
      } 
    }
  }
</script>

<style scoped>
/* Custom Dropzone */
.winedropzone {
  position: absolute;
  top: calc(220px - 4vh - 1vh);
  left:0;
  width:50vw;
  height:min(30vw,40vh);

  background-color: transparent !important;

  border-style: none;
  border-radius: 5px;

  transition: background-color .2s linear;
}
</style>