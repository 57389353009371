// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/winery_from_webname/';
// const url = WEBSERVER + ':' + PORT + '/api/winery_from_webname/';

const url = API + '/winery_from_webname/';
const debug = false;

class Winery_from_Webname {
    static getToken(postdata) {
    return axios.post(url, postdata)
        .then(response => {
            if (debug) console.log('Winery_from_Webname.getToken: ',response.data)
            return response.data
        })
        .catch(error => {
            console.log('Winery_from_Webname.getToken: ',error)
        })
    }
}

export default Winery_from_Webname