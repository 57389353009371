<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <img src="../assets/img/header.jpg" style="position:absolute; width:100%; height:100%;align='middle'">
          <div class="welcome-picture">
          </div>
          <div class="welcome-overlay">
            <h1 class="main-title">Welcome to <b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</h1>
            <p class="main-subtitle">make your winery known to all wine lovers</p>
          </div> 
        </b-col>
      </b-row> 
      <b-row>
         <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8"> 
          <p class="w-title">Our Mission<p>
          <p class="w-text">Our dream is to unify and centralize all wine information controlled by the producer and share it freely with the rest of the world. </p>

          <p class="w-text">All information entered in the WyneMaker database is shared and will be publicly available for free to feed other applications that need information about your winery; like WyneCellar, for example, which is a tool to manage your private winecellar.</p> 
          <p class="w-text">But beware, WyneMaker is for professional winemakers only!</p>
          <p class="w-text">The information in the database is solely controlled by producers and by producers only. Therefore, by entering information in WyneMaker you certify that you are a legal spokesperson for your winery and consent to share your winery and wine data.</p>

          <p class="w-text">Although WyneMaker has been developed with the uttermost care, we explicitly inform you that it is still under construction and might undergo some changes.</p>

          <p class="w-text">I hereby declare I fully qualify as a wine producer and want to ...</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right"> 
          <b-button v-on:click="gotoRegister()" class="w-button-green">register</b-button>
        </b-col>
      </b-row>   
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BeforeRegister',
    data() {
      return {
        isLoading : true,
      }
    }, 
    mounted() {
      this.isLoading = false
    },
    methods: {
      gotoRegister() {
        this.$router.push({path:'/Register'})
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style> 