// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/users/'
// const url = WEBSERVER + ':' + PORT + '/api/users/'

const url = API + '/users/'
const debug = false

class Connection {
    static test() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                resolve(true);
                if (debug) console.log('Connection.test() - Connection OK')
            } catch(err) {
                resolve(false);
                if (debug) console.log('Connection.test() - No Connection')
            }

        })
    }
}

export default Connection