<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineryPicturesMobile  v-if="matches" />
    <WineryPicturesDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineryPicturesMobile from "./WineryPictures_Mobile.vue";
  import WineryPicturesDesktop from "./WineryPictures_Desktop.vue";
 
  export default {
    name: 'WineryPictures',
    components: {
      MatchMedia,
      WineryPicturesMobile,
      WineryPicturesDesktop,
    },
  }
</script> 