<template>
  <div v-if="this.isLoading" class="title-bar">
    <b-form>
      <br><br><br><br><br>
      <h5 style='color:white'>loading</h5>
      <br>
       <b-spinner style="color: rgb(109,122,82) !important"
        variant="warning"
      ></b-spinner>
    </b-form>
  </div>
  <div v-else class="main">
    <b-row style="margin:0">
      <b-col md="8" style="height:100vh;padding-left:0!important">
        <div class="login-picture">
          <div class="photography">
            <p> photography : tim mossholder </p>
          </div>
          <div class="login-overlay">
            <h1 class="main-title"><b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</h1>
            <p class="main-subtitle">make your winery known to all wine lovers</p>
            <p v-if="database.name == 'Test'" class="main-subtitle" style="font-size: 48px !important;">{{database.name}}</p>
            <br>
            <!-- <p class="main-subtitle">TEST ONLY - will be released March, 31th 2022</p> -->
          </div>
          <div class="copyright">
            <p style="text-align:center">funware for wine lovers developed and ©copyrighted by N'JOY Information Technology</p>
          </div>  
        </div>
      </b-col>
      <b-col md="4">
        <div v-if="!this.noServer" >
          <div style="height:25vh">
          </div>
          <b-form class="w-login">
            <b-form-group>
              <label class="w-label" for="input-username">email</label>
              <b-form-input
                id="input-username"
                class = "w-input"
                style = "background-color:white !important"
                v-model="form.email"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.emailValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="email"
                required
              ></b-form-input>
              <b-form-invalid-feedback id="input-live-feedback">
                  {{emailFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group>
              <label class="w-label" for="input-password">password</label>
              <b-form-input
                v-if="passwordVisible"
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="text"
                required
              ></b-form-input>
              <b-form-input
                v-else
                id="input-password"
                class = "w-input"
                style = "background-color:white !important"
                v-on:keyup.enter="onLogin()"
                v-model="form.password"
                v-bind:class="{'form-control':true, 'is-invalid' : !this.passwordValid}"
                aria-describedby="input-live-help input-live-feedback"
                type="password"
                required
              ></b-form-input>
              <font-awesome-icon
                id="eye"
                v-bind:style="{'color':eye_color, 'font-size':'16px', 'float':'right', 'position':'relative', 'bottom':'22px','right': '5px'}"
                icon="eye"
                @click="toggleVisibilityPassword"/>
              <b-form-invalid-feedback id="input-live-feedback">
                  {{passwordFeedback}}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-button v-on:click="onLogin()" class="w-button-green-full btn-block">login</b-button>
            <p style="font-size:12px; margin-top:5px" v-on:click="forgotPassword()" class="w-cursor-click"><u>forgot password?</u></p>
          </b-form>
          <div style="height:25vh">
          </div>
          <b-form class="w-login">
            <p style="font-size:12px;margin-bottom:5px" class="w-cursor-default">don't have an account yet?</p>
            <b-button v-on:click="beforeRegister()" class="w-button-pink-full btn-block">register</b-button>
          </b-form>
        </div>
        <div v-else class="login-overlay">
          <h1 class="w-title text-center">No Server Available</h1>
          <h2 class="w-subtitle text-center">Please try again later</h2>
        </div>
        <br>
      </b-col>
    </b-row>
    <b-modal 
      id="wynemaker"
      cancel-disabled
      ok-disabled 
      title="Our WyneMaker in the Spotlight" 
      body-class="p-0"
    >
      <b-carousel
        id="carousel-fade"
        slide = true
        :interval="4000"
        controls
        background="#fffff0"
        img-width="300"
        img-height="200"
        fade
        style="text-shadow: 1px 1px 2px #333;"
      > 
        <b-carousel-slide v-for="image_file in winery.image_files" v-bind:key="image_file.dataURL">
          <template v-slot:img>
            <img
              class="d-block img-fluid w-100"  
              width="300"
              height="200"
              v-bind:src="image_file.dataURL"
            >
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="over">
        <b-row>
          <b-col>
            <h1 class="local-title">{{winery.name}}</h1>
            <p class="local-text">{{winery.description}}</p>
          </b-col>
        </b-row>         
        <hr>
        <b-row>
          <b-col>
            <p class="local-text text-center">{{winery.address1}}</p>
            <p class="local-text text-center">{{winery.address2}}</p>
            <p class="local-text text-center">{{winery.zip}} {{winery.city}}</p>
            <p class="local-text text-center">{{winery.country}}</p>
            <p class="local-text text-center">{{winery.phone}}</p>
            <p class="local-text text-center">{{winery.email}}</p>
          </b-col>           
        </b-row>     
        <hr>
        <b-row class="justify-content-center">
          <p class="local-subtitle text-center" >full information on</p>
        </b-row>
        <b-row class="justify-content-center">  
          <p class="local-subtitle text-center w-cursor-click" v-on:click="gotoWebsite()"> <b>https://www.wynemaker.com/{{winery.webname}}</b></p>
        </b-row>
      </div>
      <template #modal-footer="{ ok }">
        <b-row style="width:100%">  
          <b-col class="text-right">
            <b-button class="w-button-green" @click="ok()">
              close
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import cc64 from '../assets/js/cc64.js';

  import Database from '../Database.js';
  import Connection from '../Connection.js';
  import Authenticate from '../Authenticate.js';
  import Users from '../Users.js';

  import Wineries from '../Wineries.js'

  export default {
    name: 'Login',
    data() {
      return {
        debug: true,
        form: {
          email: '',
          password: '',
        },
        user: {
          image_files:[],
        },
        user_id:0,
        profile_picture:'',
        database:{},

        emailValid : true,
        passwordValid : true,
        error : '',
        emailFeedback : '',
        passwordFeedback : '',
        isConfirmed: false,

        /* WyneMaker in the Spotlight Stuff */
        winery: {},
        winery_id:"662776501dbf4708d7c167df",
        /* WyneMaker in the Spotlight Stuff */

        passwordVisible: false,
        eye_color:"lightgrey",

        isLoading: true,
        noServer: true,
      }
    },
    async mounted() {
      while (this.noServer) {
        if (this.debug) console.log("Login.mounted : this.noServer = ", this.noServer)
        await Connection.test()
        .then(response=>{
          this.noServer = !response;
          this.isLoading = false;
        })

        await Database.getone("657048c77872235bf4fe3e78")
        .then(response=>{
          if (this.debug) console.log("Login.mounted : await Database.get reponse = ", response);
          this.database = response;
        })
        .catch(error=>{
          if (this.debug) console.log("Login.mounted : await Database.get - ", error);
        })

        /* WyneMaker in the Spotlight Stuff */
        if (this.winery_id !=0) {
          await Wineries.getone(this.winery_id)
          .then(response=>{
            this.winery = response;
            delete this.winery._id;
            if (this.debug) console.log("Login.mounted: Winery "+ this.winery.name + " succesfully fetched from DB");
          })
          .catch(error=>{
            if (this.debug) console.log("Login.mounted: catch await Wineries.getone ERROR : ", error);
          })
          if (this.debug) console.log("Login.mounted : WyneMaker in the Spotlight is ", this.winery.name)
          this.$bvModal.show('wynemaker')
        }
        /* WyneMaker in the Spotlight Stuff */  
      }
    },
    methods: { 
      validate() {
        // validate email 
        let hasAt = false;
        let hasDot = false;
        for (let i=0;i < this.form.email.length;i++) {
            if (this.form.email[i] === '@') {
                hasAt = true;
            }
            if (this.form.email[i] === '.') {
                hasDot = true;
            }
        }
        this.emailValid = hasAt && hasDot
        if ( ! this.emailValid) {
            this.emailFeedback='This is an invalid email format';
            this.passwordValid = true;
        }
      },  
      async onLogin() {
        if ( this.noServer ) {
          return;
        }
        this.validate();

        let challenge = {"email": this.form.email, "hashed_password": this.CryptoJS.SHA256(this.form.password).toString()}
        if(this.emailValid) {
          let authOK = false;
          await Authenticate.getToken(challenge)
          .then(response=>{     
            if (this.debug) console.log("Login in onLogin : response ", response, "successfully fetched from DB");
            if (response === 0) {
              this.emailValid = false;
              this.emailFeedback = 'email is unknown, maybe you need to register';
              this.passwordValid = true;
            } else if (response === 1) {
              this.passwordValid = false;
              this.passwordFeedback = 'password incorrect';
            } else if (response === 2) {
              this.emailValid = false;
              this.emailFeedback = 'email is registered, but not confirmed, please check you mail'
              this.passwordValid = true;
            } else {
              this.user_id = response;
              authOK = true;
            }
          })
          .catch(error=>{
            if (this.debug) console.log("Login in onLogin : await Authenticate.getToken - ", error);
            return
          })

          if (authOK) {
            await Users.getone(this.user_id)
            .then(response=>{
              if (this.debug) console.log("Login in onLogin : await Users.getone reponse = ", response);
              this.user = response;
              delete this.user._id;
            })
            .catch(error=>{
              if (this.debug) console.log("Login in onLogin : await Users.getone - ", error);
            })

            if (this.user.winery_id == 0 || typeof this.user.winery_id == 'undefined') {
              this.$router.push({path:'/AddWinery2Registration', query: {user_id: this.user_id}} );
            } else {
              // HERE YOU FIRE OF THE ROUTER TO ACCESS THE APPLICATION
              try {
                this.$store.commit('login')
                this.$store.commit('setUser',this.user)
                this.$store.commit('setUserID',this.user_id)
                
                if ( this.user.image_files.length > 0) {
                  this.profile_picture = await cc64(this.user.image_files[0].dataURL,1,1)
                }
                this.$store.commit('setProfilePicture',this.profile_picture)
                if (this.user.showWelcome) {
                  this.$store.commit('setPage','Welcome')
                  this.$router.push({path:'/Winery/Welcome', query: {winery_id:this.user.winery_id }} );
                } else {
                  this.$store.commit('setPage','General')
                  this.$router.push({path:'/Winery/General', query: {winery_id:this.user.winery_id }} );
                }
                
              } catch(err) {
                alert("ERROR : ", err)
              }
            }
          }
        } 
      },
      beforeRegister() {
        this.$router.push({path:'/BeforeRegister'} );  
      },
      forgotPassword() {
        this.$router.push({path:'/ForgotPassword'} );
      },

      /* WyneMaker in the Spotlight Stuff */
      gotoWebsite() {
        window.open('https://www.wynemaker.com/'+this.winery.webname, '_blank');
      },
      /* WyneMaker in the Spotlight Stuff */

      async toggleVisibilityPassword() {
        if (this.passwordVisible == true) {
          this.passwordVisible = false
          this.eye_color = "lightgrey"
        } else {
          this.passwordVisible = true
          this.eye_color = "rgb(109,122,82)"
        }
      },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.main {
  overflow-x: hidden;
  overflow-y: hidden;
}
.login-picture {
    height:100vh !important;
    width:100% !important;
    
    background-image: url("../assets/img/wynemaker-desktop.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:absolute;
    display: flex;
    justify-content: center;
}
.login-overlay {
  position: relative;
  top: 35vh;
}

.copyright {
  position: fixed;
  font-size:clamp(8px,1.5vw, 14px)!important;
  bottom: 0px;
  color: white;
}

.title-bar {
  text-align: center;
}


/* WyneMaker in the Spotlight Stuff */
.over {
    position: relative;
    width:90%;
    top: -20px;
    left: 50%;
    z-index: 3;
    color: black;
    padding: 3% 5% 5% 5%;
    background-color:white;
    border-radius: 1%;
      -webkit-transform: translate3d(-50%,0,0);
         -moz-transform: translate3d(-50%,0,0);
          -ms-transform: translate3d(-50%,0,0);
           -o-transform: translate3d(-50%,0,0);
              transform: translate3d(-50%,0,0);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
       -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.17);
}
.modal-body {
  padding:0 !important
}

.local-title {
  font-size: 28px!important;
  font-weight: bold;
  color:rgb(112,112,112) !important;
  text-align:left;
  margin: 10px 0 10px 0;
}
.local-subtitle {
  font-size:14px!important;
  color:rgb(112,112,112) !important;
  text-align:left;

}
.local-text {
  font-size: 13px!important;
  color:rgb(112,112,112) !important;
  text-align:left;
  margin:0;
  hyphens: auto;
  white-space: pre-wrap;
}
/* WyneMaker in the Spotlight Stuff */
</style>
