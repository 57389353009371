<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
          <b-spinner style="color: rgb(109,122,82) !important"
          variant="warning"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <br>
      <b-row style="height:40vh;margin:0" class="welcome-picture">
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" style="height:100%">
          <div class="welcome-overlay">
            <h1 class="main-title">Welcome to <b><span style="color: rgb(195,147,130)">WYNE</span></b>MAKER</h1>
            <p class="main-subtitle">make yourself known to all wine lovers</p>
          </div>  
        </b-col>
      </b-row> 
      <br>
      <br>
      <br>
      <b-row>
        <b-col cols="1" sm="2">
        </b-col>
        <b-col cols="10" sm="8" class="text-right">
          <br>
          <p class="w-title">We have received your request for registration</p>
          <p class="w-text">In a few moments you will receive an email from <strong>WyneMaker</strong>, where you can confirm your registration.</p>
          <p class="w-text">Good Luck</p> 
          <br>
          <p class="w-subtitle">PS: Also check your spam.</p>
          <b-button v-on:click="onBack()" class="w-button-green">back</b-button>
        </b-col>
      </b-row>  
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RegistrationRecieved',
    data() {
      return {
        debug:false,

        isLoading : true,
      }
    },
    mounted() {
       this.isLoading = false;
    },
    methods: {
        async onBack() {
          if (this.debug) console.log("RegistrationReceived in onBack: Back pressed");
          this.$router.push({path:'/'});
        },
    }
  }
</script>

<style>
@import url("../assets/styles/wynestyle.css");
</style>

<style scoped>
.welcome-picture {
    width:100%;
    height:auto;
    
    background-image: url("../assets/img/header.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position:relative;
}
.welcome-overlay {
  position: relative;
  top: 10vh;
}
</style>