// import { WEBSERVER } from './Webserver.js';
// import { PORT } from './Port.js';

import { API } from './API.js';
import axios from 'axios';

// const url = WEBSERVER + ':3000/api/cellars/'
// const url = WEBSERVER + ':' + PORT + '/api/cellars/'

const url = API + '/cellars/'

class Cellars {
    // get cellars
    static get() {
        return new Promise ( async (resolve, reject) => {
            try {
                const res = await axios.get(url);
                const data = res.data;
                resolve(
                    data.map(cellar=> ({
                        ...cellar,
                        name: cellar.name
                    }))
                );
                //console.log("data = " + data)
            } catch(err) {
                reject(err);
            }

        })
    }

    // get one cellar
    static getone(id) {
        return axios.get(`${url}${id}`)
            .then(response => {
                //console.log('Cellars.getone: ',response.data)
                return response.data
            })
            .catch(error => {
                console.log('Cellars.getone: ',error)
            })
    }

    // create cellar
    static create(postdata) {
        return axios.post(url, postdata)
            .then(response => {
                //console.log('Cellars.create: ',response)
                return response.data._id
            })
            .catch(error => {
                console.log('Cellars.create: ',error)
            })
    }

    static update(id,postdata) {
        // console.log('Cellars.update: id=',id)
        // console.log('Cellars.update: postdata=',postdata)
        return axios.put(`${url}${id}`, postdata)
            .then(response => {
                //console.log('Cellars.update: ',response)
                return response
            })
            .catch(error => {
                console.log('Cellars.update ERROR: ',error)
            })
    }

    // delete cellar
    static delete(id) {
        return axios.delete(`${url}${id}`)
    } 
}

export default Cellars