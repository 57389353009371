<template>
  <div>
    <b-container v-if="isLoading" class="text-center d-flex justify-content-center">
      <b-form>
        <br><br><br>
        <h5>loading</h5>
        <br>
        <b-spinner style="color: rgb(109,122,82) !important"
        ></b-spinner>
      </b-form>
    </b-container>
    <div v-else>
      <b-container fluid v-if="this.inSession">
        <b-row>
          <b-col md="2" style="padding:0">
            <Menu/>
          </b-col>
          <b-col md="10" style="padding:0" align="center">
            <div style="height:15vh">
            </div>
            <div class="w-box" style="width:60%">
              <p class="w-subtitle" style="text-align:right">general information</p>
              <b-form autocomplete="off">
                <b-form-group style="text-align:left">
                  <label class="w-label w-required" for="input-name">winery name</label>
                  <b-form-input
                  id="input-name"
                  class="w-input"
                  v-model="form.name"
                  v-bind:class="{'form-control':true, 'is-invalid' : !this.nameValid}"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Enter winery name"
                  @input="changeWebname"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback">
                    {{nameFeedback}}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group style="text-align:left">
                  <label class="w-label" for="input-description">winery description</label>
                  <b-form-textarea
                    id="input-description"
                    class="w-textarea-big"
                    v-model="form.description"
                    placeholder="Enter winery description"
                    rows="10"
                    max-rows="15"
                  ></b-form-textarea>
                </b-form-group>
              </b-form>
            </div>
            <div class="text-right" style="width:60%">
            <b-button v-on:click="onNext()" class="w-button-green">next</b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <NoAccess v-else></NoAccess>
    </div>
  </div>
</template>

<script>
  import makeWebname from '../assets/js/makeWebname.js';
  import Winery_from_Webname from '../Winery_from_Webname.js';

  import NoAccess from './NoAccess.vue'
  import Menu from './MenuDesktop.vue'

  import Wineries from '../Wineries.js'

  export default {
    name: 'WineryGeneral',
    components: {
      NoAccess,
      Menu
    },
    computed : {
      inSession() {
        return this.$store.state.inSession
      },
    },
    data() {
      return {
        debug: false,

        isLoading: true,

        form: {
          name : '',
          description: '',
          webname: '',
          wine_ids:[]
        },
        winery_id:0,

        nameValid : true,
        nameFeedback : '',
        isValid : false,
      }
    }, 
    async mounted() {
      if (this.debug) console.log('WineryGeneral in mounted(): Fetching winery from DB '+ this.$route.query.winery_id);

      await Wineries.getone(this.$route.query.winery_id)
      .then(response=>{
        this.form = response;
        this.winery_id = response._id;
        delete this.form._id;
        if (this.debug) console.log( "WineryGeneral in mounted(): Winery "+ this.form.name + " succesfully fetched from DB");
      })
      .catch(error=>{
        if (this.debug) console.log( "WineryGeneral in mounted(): catch await Wineries.getone - ERROR : ", error);
      })

      this.isLoading = false
    },
    methods: {
      validate(){
        if( this.form.name.length >= 3) {
            this.nameValid = true;
        } else {
            this.nameValid = false
            this.nameFeedback = "winery name should at least be 3 characters long"
        }
        this.isValid = this.nameValid;
      },  
      async onSave() {
        this.validate();
        if(this.isValid){
          await Wineries.update(this.$route.query.winery_id,this.form)
          .then(response => {
             if (this.debug) console.log("WineryGeneral in onSave: Submitted - ", response)
             return 'done'
          })
          .catch(error => {
            if (this.debug) console.log("WineryGeneral in onSave: catch await Winerues.update - ", error)
            return 'error'
          })
        }
      },
      async onNext() {
        await this.onSave()
        .then(response=>{
          if (this.debug) console.log("WineryGeneral in onNext():", response)
          this.$store.commit('setPage','Contact')
          this.$router.push({path:'/Winery/Contact', query: {winery_id:this.$route.query.winery_id }} );
        })
      },
      onReset() {
          this.form.name = '';
          this.form.description = '';
          this.form.webpage = '';
      },

      async changeWebname() {
        let doSearch = true;
        let i = 0;
        let root_webname = makeWebname(this.form.name)
        let try_webname = root_webname

        while (doSearch) {
          if (this.debug) console.log("WineEdit in changeWebname: trying ", try_webname);
          let challenge = {"webname": try_webname};
          await Winery_from_Webname.getToken(challenge)
          .then(response => {
            if (response === 0) {
              if (this.debug) console.log("WineEdit in changeWebname: winery with webname ", try_webname, " has no id and does NOT exists in DB");
              doSearch = false;
            } else {
              if (this.debug) console.log("WineEdit in changeWebname: winery with webname ", try_webname, " has id = ", response, " and exists in DB");
              if (response != this.winery_id) {
                doSearch = true;
                i++;
                try_webname = root_webname + '_' + i
              } else {
                if (this.debug) console.log("WineEdit in changeWebname: winery remains the same.")
                doSearch = false;
              }
            }
          })
          .catch(error => {
            doSearch = true;
            if (this.debug) console.log("WineEdit in changeWebname: await Winery_from_Webname.getToken - ", error);
          })
        }

        this.form.webname = try_webname
        
        if (this.debug) console.log("WineEdit in changeWebname: webname ", try_webname, " will be used.");
      }
    }
  }
</script>

<style>
</style> 
<style scoped>
</style>