<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineryGeneralMobile  v-if="matches" />
    <WineryGeneralDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineryGeneralMobile from "./WineryGeneral_Mobile.vue";
  import WineryGeneralDesktop from "./WineryGeneral_Desktop.vue";
 
  export default {
    name: 'WineryGeneral',
    components: {
      MatchMedia,
      WineryGeneralMobile,
      WineryGeneralDesktop,
    },
  }
</script> 