<template>
  <MatchMedia query="(max-width: 768px)" v-slot="{ matches }">
    <WineEditMobile  v-if="matches" />
    <WineEditDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineEditMobile from "./WineEdit_Mobile.vue";
  import WineEditDesktop from "./WineEdit_Desktop.vue";
 
  export default {
    name: 'WineEdit',
    components: {
      MatchMedia,
      WineEditMobile,
      WineEditDesktop,
    },
  }
</script> 