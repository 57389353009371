<template>
  <MatchMedia query="(max-width: 760px)" v-slot="{ matches }">
    <WineShowMobile  v-if="matches" />
    <WineShowDesktop v-else />
  </MatchMedia>
</template>
 
<script>
  import { MatchMedia } from "vue-component-media-queries";
  import WineShowMobile from "./WineShow_Mobile.vue";
  import WineShowDesktop from "./WineShow_Desktop.vue";
 
  export default {
    name: 'WineShow',
    components: {
      MatchMedia,
      WineShowMobile,
      WineShowDesktop,
    },
  }
</script> 